import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-generic-badge',
    templateUrl: './generic-badge.component.html',
    styleUrls: ['./generic-badge.component.scss'],
})
export class GenericBadgeComponent {
    @Input() size: 'sm' | 'md' | 'lg' = 'md';
    @Input() icon: null | 'dot' = null;
    @Input() color: 'client' | 'gray' | 'blue' | 'red' | 'yellow' | 'green' = 'gray';
    @Input({ required: true }) label!: string;
    @Input() dotBadge: boolean = false;
    @Input() isInteractive = false;
    @Input() ariaLabel?: string;
    @Output() badgeClick = new EventEmitter<Event>();

    click(event: Event): void {
        this.badgeClick.emit(event);
    }
}
