<app-modal
    [saveBtnText]="saveBtnText"
    *ngIf="showConsent"
    [showCloseBtn]="showCloseBtn"
    [(visible)]="showConsent"
    [context]="this"
    [saveFunc]="saveFunc"
    [closeFunc]="closeFunc"
    [modalWidth]="'90vw'"
    [modalHeight]="'90vh'"
>
    <div class="modal-heading" *ngIf="heading">{{ heading | translate }}</div>
    <div class="modal-desc" *ngIf="subHeading">{{ subHeading | translate }}</div>

    <div *ngIf="ViewDocSignError" class="error">
        <p *ngIf="showImpliedConsentMsg">{{ 'Consent.WaiverOrImpliedConsent' | translate }}</p>
        <p *ngIf="!showImpliedConsentMsg">{{ 'Consent.ViewDocSignError' | translate }}</p>
    </div>

    <div *ngIf="!ViewDocSignError" [style.height]="docHeight">
        <app-loading-component [loading]="!url" [text]="'Consent.LoadingConsentDoc'">
            <iframe
                style="width: 100%; height: 72vh"
                *ngIf="url"
                class="responsive-iframe pb-65"
                [src]="url"
                frameBorder="0"
            ></iframe>
        </app-loading-component>
    </div>
</app-modal>
