import { Injectable } from '@angular/core';
import {
    signOut,
    signUp,
    signIn,
    resetPassword,
    confirmResetPassword,
    updatePassword,
    confirmSignUp,
    confirmSignIn,
    signInWithRedirect,
    getCurrentUser,
    fetchUserAttributes,
    fetchAuthSession,
    updateUserAttributes,
    verifyTOTPSetup,
    UpdateUserAttributesInput,
} from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Subject, Observable } from 'rxjs';
import { AuthUser, updateMFAPreference } from '@aws-amplify/auth';
import { ConfigService } from './config.service';
import { UserService } from './user.service';

import { environment as env } from '@environment/environment';
import { TemplateTypes } from '@h20-services/models/emails/email_template_types';
import { RequestService } from './request.service';
import { PulseAuth } from './models/PulseAuth';
import { PulseParticipant } from '@h20-services/models/PulseParticipant';
import { ClaimService } from '@h20-services/claim.service';
import { ParticipantService } from '@h20-services/participant.service';

export interface NewUser {
    email: string;
    username: string;
    phone?: string;
    password: string;
    firstName?: string;
    lastName?: string;
    id?: string;
    role?: string;
    preferred_name?: string;
    nickname?: string;
    tableauUsername?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public loggedIn: boolean;
    private _authState: Subject<AuthUser | any> = new Subject<AuthUser | any>();
    authState: Observable<AuthUser | any> = this._authState.asObservable();

    public static SIGN_IN_EVENT = 'signIn';
    public static SIGN_OUT_EVENT = 'signOut';
    public static FACEBOOK = 'Facebook';
    public static GOOGLE = 'Google';
    private pulseAuth: PulseAuth;
    private userAttributes;

    constructor(
        private reqSvc: RequestService,
        private configsvc: ConfigService,
        private userSvc: UserService,
        private claimSvc: ClaimService,
        private prtSvc: ParticipantService
    ) {
        Hub.listen('auth', (data) => {
            const { channel, payload } = data;
            if (channel === 'auth') {
                this._authState.next(payload.event);
            }
        });
    }

    forgotPassword(email: string): any {
        let url = window.location.href.split('/')[2];
        const clientMetadata = { registryId: this.configsvc.getTenant(), url: url };
        return resetPassword({ username: email, options: { clientMetadata } });
    }

    resetPassword(email: string, code: string, newPassword: string): any {
        return confirmResetPassword({ username: email, confirmationCode: code, newPassword });
    }

    changePassword(user: AuthUser, oldPassword: string, newPassword: string): any {
        return updatePassword({ oldPassword, newPassword });
    }

    sendActivationEmail(user: any): any {
        // Send data to email lambda
        return this.reqSvc.post(env.API.sendEmailTemplate, user);
    }

    sendAdminActivationEmail(user: any): any {
        // Send data to email lambda
        return this.reqSvc.post(env.API.sendEmailTemplate, user);
    }

    forgotPasswordEmail(email: any): any {
        // Submit the registration
        const reqObj: any = {
            email: email,
            registry_id: this.configsvc.getTenant(),
            templateType: TemplateTypes.forgot_password,
        };

        return this.reqSvc.post(env.API.sendEmailTemplate, JSON.stringify(reqObj));
    }

    changePasswordEmail(email: any, pref_language): any {
        // Submit the registration
        const reqObj: any = {
            email: email,
            registry_id: this.configsvc.getTenant(),
            pref_language: pref_language,
            templateType: TemplateTypes.change_password,
        };

        return this.reqSvc.post(env.API.sendEmailTemplate, JSON.stringify(reqObj));
    }

    getPortalLink(email, pref_language, templateType): any {
        const reqObj: any = {
            operation: 'getPortalLink',
            email: email,
            registry_id: this.configsvc.getTenant(),
            pref_language: pref_language,
            templateType: templateType,
        };

        return this.reqSvc.post(env.API.sendEmailTemplate, JSON.stringify(reqObj));
    }

    signUp(user: NewUser): Promise<AuthUser | any> {
        const attributes: any = {
            email: user.email,
            nickname: user.nickname,
        };
        if (user.tableauUsername) {
            attributes['custom:tableau_username'] = user.tableauUsername;
        }

        return signUp({
            username: user.username,
            password: user.password,
            options: { userAttributes: attributes },
        });
    }

    confirmSignUp(username: string, code: string, options: any = {}): any {
        return confirmSignUp({ username, confirmationCode: code, options });
    }

    signIn(username: string, password: string): Promise<AuthUser | any> {
        return new Promise((resolve, reject) => {
            signIn({
                username,
                password,
                options: {
                    clientMetadata: {
                        registry_id: this.configsvc.getTenant(),
                    },
                },
            })
                .then((user: AuthUser | any) => {
                    this.loggedIn = true;
                    resolve(user);
                })
                .catch((error: any) => reject(error));
        });
    }

    //Confirm sign in using MFA
    confirmSignIn(user, code, challengeName) {
        return new Promise((resolve, reject) => {
            confirmSignIn({
                challengeResponse: code,
                options: {
                    clientMetadata: {
                        registry_id: this.configsvc.getTenant(),
                    },
                },
            })
                .then((loggedUser) => {
                    resolve(loggedUser);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    signOut(): Promise<any> {
        return signOut().then(() => (this.loggedIn = false));
    }

    socialSignIn(provider): Promise<any> {
        return signInWithRedirect({ provider });
    }

    createToken(email: string): Observable<any> {
        const reqObj = { operation: 'create', data: { email } };
        return this.reqSvc.post(env.API.getToken, JSON.stringify(reqObj));
    }
    getTokenData(token: string) {
        const reqObj = { operation: 'get', data: { token } };
        return this.reqSvc.post(env.API.getToken, JSON.stringify(reqObj));
    }

    // To Do: Break this call into refreshPulseAuth and getPulseAuth. This call is being used to do both.
    // To Do: Make pulseAuth a singleton.
    // This function is called many times, instantiating many different instance of pulseAuth.
    // If the users claims are updated during a session, any instances that isn't manually updated will be out of date.
    async getPulseAuth(): Promise<PulseAuth> {
        return new Promise((resolve, reject) => {
            getCurrentUser()
                .then(async (user) => {
                    // Need to cache the result of the fetchUserAttributes api call. Amplify caches the session fetch but not the attributes.
                    // getPulseAuth is called frequently during loading of some pages.
                    // As a result, fetchUserAttributes was returning an error "Too many requests exception"
                    // It is okay to cache the attributes. The front end does not pull mutable data from the attributes. The mutable data is in the session.
                    // TO DO: Remove Amplify from Healthie.
                    if (!this.userAttributes) {
                        this.userAttributes = await fetchUserAttributes();
                    }

                    const session = await fetchAuthSession();
                    if (!session.tokens) {
                        throw new Error('No authenticated user session found');
                    }
                    this.pulseAuth = new PulseAuth(this.userAttributes, session);
                    resolve(this.pulseAuth);
                })
                .catch((error: any) => reject(error));
        });
    }

    setPreferredMfa(user, pref_mfa) {
        updateMFAPreference({ sms: 'DISABLED', totp: 'PREFERRED' });
    }

    verifyTOTPSetupToken(user, code): Promise<any> {
        return new Promise((resolve, reject) => {
            verifyTOTPSetup({ code: code })
                .then((res) => {
                    resolve(res);
                })
                .catch((error: any) => reject(error));
        });
    }

    async completeSetPulseAuth(): Promise<PulseAuth> {
        let pulseAuth;
        try {
            pulseAuth = await this.getPulseAuth();
            // Set current participant if the user has multiple patients
            if (pulseAuth.getParticipants().length > 0) {
                const participants: PulseParticipant[] = [];
                for (const p of pulseAuth.getParticipants()) {
                    const pulsePrt = new PulseParticipant(p);
                    participants.push(pulsePrt);
                }
                const latestPrt = PulseParticipant.getLastCreated(participants);
                this.prtSvc.setCurrentParticipant(latestPrt.getUserID());
                this.prtSvc.setCurrentParticipantName(latestPrt.getPreferredName());
            } else {
                this.prtSvc.clearCurrentParticipant();
            }

            // Set user claims in advance
            // ToDo: Can be replaced with PulseAuth claims properties.
            this.claimSvc.setUserClaims(pulseAuth.getClaims());

            return pulseAuth;
        } catch (err) {
            throw err;
        }
    }

    async setCurrentJurisdiction(jurisdiction: string = this.configsvc.getTenant()) {
        let attribute: UpdateUserAttributesInput = {
            userAttributes: { 'custom:currentJurisdiction': jurisdiction },
        };
        await updateUserAttributes(attribute);
    }

    async refreshToken() {
        return fetchAuthSession({ forceRefresh: true });
    }

    async setCurrentRole(role: string) {
        let attribute: UpdateUserAttributesInput = {
            userAttributes: { 'custom:currentRole': role },
        };
        await updateUserAttributes(attribute);
    }

    async getSession() {
        return fetchAuthSession();
    }

    getUserSub() {
        return this.pulseAuth.getAuthenticationID();
    }
}
