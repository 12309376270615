export const ELEMENT_TYPES = [
    {
        icon: 'fa-circle-dot',
        color: 'color-1',
        text: 'radiogroup',
        displayText: 'Radiogroup',
    },
    { icon: 'fa-check', color: 'color-1', text: 'checkbox', displayText: 'Checkbox' },
    { icon: 'fa-text', color: 'color-2', text: 'text', displayText: 'Text' },
    { icon: 'fa-9', color: 'color-2', text: 'number', displayText: 'Number' },
    { icon: 'fa-phone', color: 'color-2', text: 'phone', displayText: 'Phone' },

    {
        icon: 'fa-calendar-range',
        color: 'color-2',
        text: 'partial-date',
        displayText: 'Partial Date',
    },
    { icon: 'fa-calendar-day', color: 'color-2', text: 'date', displayText: 'Date' },
    {
        icon: 'fa-calendar-days',
        color: 'color-2',
        text: 'datepicker',
        displayText: 'Datepicker',
    },
    { icon: 'fa-timer', color: 'color-2', text: 'time', displayText: 'Time' },

    {
        icon: 'fa-chevron-down',
        color: 'color-1',
        text: 'dropdown',
        displayText: 'Dropdown',
    },
    {
        icon: 'fa-chevrons-down',
        color: 'color-1',
        text: 'multiselect',
        displayText: 'Multiselect',
    },
    { icon: 'fa-at', color: 'color-2', text: 'email', displayText: 'Email' },

    { icon: 'fa-comment', color: 'color-2', text: 'comment', displayText: 'Comment' },

    { icon: 'fa-code', color: 'color-5', text: 'html', displayText: 'HTML' },

    {
        icon: 'fa-ellipsis-stroke',
        color: 'color-2',
        text: 'multipletext',
        displayText: 'Multiple Text',
    },
    {
        icon: 'fa-map',
        color: 'color-3',
        text: 'gesture-map-input',
        displayText: 'Gesture Map',
    },
    { icon: 'fa-map-pin', color: 'color-3', text: 'gesture', displayText: 'Gesture' },
    {
        icon: 'fa-arrow-up-from-bracket',
        color: 'color-3',
        text: 'file',
        displayText: 'File Upload',
    },

    {
        icon: 'fa-link',
        color: 'color-5',
        text: 'link-generator',
        displayText: 'Survey Link Generator',
    },
    { icon: 'fa-star', color: 'color-1', text: 'rating', displayText: 'Rating' },
    { icon: 'fa-slider', color: 'color-2', text: 'slider', displayText: 'Slider' },
    {
        icon: 'fa-square-list',
        color: 'color-4',
        text: 'paneldynamic',
        displayText: 'Dynamic Panel',
    },
];
