import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { SurveyService } from '@h20-services/survey.service';
import { IFormChoice } from '@h20-services/models/forms/IFormChoice';
import { RequestService } from '@h20-services/request.service';
import { UuidService } from '@h20-services/uuid.service';
import { AuthService } from '@h20-services/auth.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { IFormDropdown } from '@h20-services/models/forms/types/IFormDropdown';

@Component({
    selector: 'app-pi-control-dropdown',
    templateUrl: './pi-control-dropdown.component.html',
    styleUrls: ['./pi-control-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlDropdownComponent,
        },
    ],
})
export class PiControlDropdownComponent
    extends AbstractPiControlComponent<IFormDropdown, any>
    implements OnInit
{
    @Input() patientId: any;
    @Input() surveyId: any;
    loading = false;
    urlChoices: any[];
    //we need to use a stand for the form value so that we can deselect
    currentChoice: any;

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        private req_svc: RequestService,
        protected translate: TranslateService,
        private survey_svc: SurveyService,
        private authService: AuthService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    async ngOnInit() {
        super.ngOnInit();
        if (this.element.hasOwnProperty('noChoicesError')) {
            this.formControl.addValidators(this.noChoicesValidator);
            this.formControl.markAsTouched();
        }

        this.currentChoice = this._value;
    }

    writeValue(value: any): void {
        //override to sync the dropdown UI on load
        //don't set current choice unless it has a value - for null currentChoice should be '' so it shows 'Select Response'
        //we have this here so when the initial value loads, it will show that in the UI component
        if (value !== null) this.currentChoice = value;
        super.writeValue(value);
    }

    selectionMade(event: any) {
        //need to write the value to show in the current selection including ''
        this.currentChoice = event.target.value;
        this.markAsTouched();
        // if the value is 'Select Response' - set the form value to null
        if (event.target.value === '') {
            this.writeValue(null);
        } else this.writeValue(event.target.value);
        this.notifyValueChange();
    }

    async setStringValue() {
        if (this._value === null || !this.element.choices) return;

        const choice = this.element.choices.find((c) => c.value == this._value);
        this.stringValue = this.getText(choice?.text);
    }

    noChoicesValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            //always returns
            return { noChoices: { value: this.translate.instant('Surveys.PleaseCloseForm') } };
        };
    }
}
