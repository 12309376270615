import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ConfigService } from './config.service';

import { environment as env } from '@environment/environment';
import { RequestService } from './request.service';
import { CommonService } from './common.service';
import { CacheService } from './cache.service';
import { PatientFileConfig } from './models/patientFileConfig';

@Injectable({
    providedIn: 'root',
})
export class PatientService {
    constructor(
        private reqSvc: RequestService,
        public storage: StorageMap,
        private configSvc: ConfigService,
        private commonSvc: CommonService,
        private cacheService: CacheService
    ) {}

    getPatientList(
        userId: string,
        sites: any[],
        logInfo: any = null,
        refreshPage: boolean,
        showPatientSuccess?: boolean
    ) {
        const cacheKey = `patients_${userId}`;
        let dataToSend = {
            registry: this.configSvc.getTenant(),
            sites: sites,
            logInfo: logInfo,
        };
        const req: any = { operation: 'getPatientList', data: dataToSend };
        return this.cacheService.cacheObservable(
            cacheKey,
            this.reqSvc.post(env.API.writeToDBPatient, req),
            600000,
            refreshPage,
            showPatientSuccess
        );
    }

    getPatientsBySites(sites: any[]): any {
        let dataToSend = {
            registry: this.configSvc.getTenant(),
            sites: sites,
        };
        const req: any = { operation: 'getPatientsBySites', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }

    filterPatientsByAnswer(question_name, answer): any {
        let dataToSend = {
            registry_key: this.configSvc.getTenant(),
            question_name: question_name,
            answer: answer,
        };
        const req: any = {
            operation: 'filterPatientsbyAnswer',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }

    setExternalIdentifier(patientID, extID): any {
        let body = {
            external_identifier: extID,
            patient_id: patientID,
        };
        return this.reqSvc.post(env.API.setExternalIdentifierURL, JSON.stringify(body));
    }

    getContact(id: string): any {
        let dataToSend = {
            id: id,
        };
        const req: any = { operation: 'getContact', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }

    getPatient(id: string): any {
        let dataToSend = {
            id: id,
            registry: this.configSvc.getTenant(),
        };
        const req: any = { operation: 'getPatient', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }

    getPatientByCaseReviewQueueId(caseReviewQueueId: string): any {
        let dataToSend = {
            caseReviewQueueID: caseReviewQueueId,
        };
        const req: any = {
            operation: 'getPatientByCaseReviewId',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }

    getProxyByPatientID(id: string): any {
        let dataToSend = {
            id: id,
        };
        const req: any = { operation: 'getProxyByPatientID', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }

    getPatientByProxyID(proxyID: string): any {
        if (!proxyID || proxyID == null) return of([]);

        const req: any = {
            operation: 'getPatientByProxyID',
            data: {
                proxy_ID: proxyID,
            },
        };
        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }

    getRestrictedPatient(patientId: string) {
        const dataToSend = {
            id: patientId,
        };
        const req: any = { operation: 'getRestrictedPatient', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }

    updatePatientStatus(ids: any[], dataStatusID: any, caseReviewQueueID: any): any {
        const req: any = {
            operation: 'updatePatientStatus',
            data: {
                ids: ids,
                dataStatusID: dataStatusID,
                caseReviewQueueID: caseReviewQueueID,
            },
        };

        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }

    hardDeletePatient(dataToSend, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req: any = { data: dataToSend };
        return this.reqSvc.post(targetEnv.API.hardDeletePatient, req, targetEnv);
    }

    isDateInFuture(dateval: Date): boolean {
        const now = new Date();
        const bd = new Date(dateval);
        const years = now.getFullYear() - bd.getFullYear();
        const months = now.getMonth() - bd.getMonth();
        const days = now.getDate() - bd.getDate();
        if (
            years < 0 ||
            (years === 0 && months < 0) ||
            (years === 0 && months === 0 && days <= 0)
        ) {
            return true;
        } else return false;
    }

    getPatientFileConfig(patient_ID: string): Observable<PatientFileConfig> {
        let dataToSend = {
            registry: this.configSvc.getTenant(),
            patient_ID: patient_ID,
        };
        const req: any = {
            operation: 'getPatientFileConfig',
            data: dataToSend,
        };
        //response includes a $metadata chunk and an Item chunk, we just return the Item
        return this.reqSvc.post(env.API.writeToDBClinicalData, req).pipe(map((resp) => resp.Item));
    }

    getPatientActivationStatus(patient_ID: string): any {
        let dataToSend = {
            patient_ID: patient_ID,
        };
        const req: any = {
            operation: 'getPatientActivationStatus',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    getPatientFileConfigBySite(site_ID: string): any {
        let dataToSend = {
            registry: this.configSvc.getTenant(),
            site_ID: site_ID,
        };
        const req: any = {
            operation: 'getPatientFileConfigBySite',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    //-- Clinical Data methods ------------------------------------
    createClinicalData(
        id,
        survey_ID,
        submission_ID,
        status,
        entry_user_ID,
        entry_user_name,
        formIndex,
        tabIndex,
        tab_form_submission_id
    ): any {
        let dataToSend = {
            id: id, // patient_ID
            survey_ID: survey_ID,
            submission_ID: submission_ID,
            status: status,
            entry_user_ID: entry_user_ID,
            entry_user_name: entry_user_name,
            form_index: formIndex,
            tab_index: tabIndex,
            tab_form_submission_id: tab_form_submission_id,
        };
        const req: any = {
            operation: 'createClinicalData',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    updateClinicalData(id, survey_ID, submission_ID, status, entry_user_ID, entry_user_name): any {
        let dataToSend = {
            id: id,
            survey_ID: survey_ID,
            submission_ID: submission_ID,
            status: status,
            entry_user_ID: entry_user_ID,
            entry_user_name: entry_user_name,
        };
        const req: any = {
            operation: 'updateClinicalData',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    deleteClinicalData(patient_ID, survey_ID, submission_ID): Observable<any> {
        let dataToSend = {
            patient_ID: patient_ID,
            survey_ID: survey_ID,
            submission_ID: submission_ID,
        };
        const req: any = {
            operation: 'deleteClinicalData',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    getClinicalDataByPatient(patient_ID): any {
        let dataToSend = {
            patient_ID: patient_ID,
        };
        const req: any = {
            operation: 'getClinicalDataByPatient',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    getChangeLog_PreferredName(userIDs): any {
        let dataToSend = {
            userIDs: userIDs,
        };
        const req: any = {
            operation: 'getChangeLog_PreferredName',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    getPatientsForRetentionPeriod(site, statusList, standardDate, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        let dataToSend = {
            sites: site,
            statusList: statusList,
            standardDate: standardDate,
        };
        const req: any = {
            operation: 'getPatientsForRetentionPeriod',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.API.writeToDBPatient, req, targetEnv);
    }

    saveClinicalToDoResponse(response, taskId, userId, patientId) {
        let dataToSend = {
            patient_ID: patientId,
            userId: userId,
            taskId: taskId,
            toDoResponse: response,
        };
        const req: any = {
            operation: 'saveClinicalToDoResponse',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    getClinicalToDoResponsesByPatient(patientId) {
        let dataToSend = {
            patient_ID: patientId,
        };
        const req: any = {
            operation: 'getClinicalToDoResponsesByPatient',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    createClinicalDataTabs(id, tab_form_submission_id, entry_user_ID, entry_user_name): any {
        let dataToSend = {
            id: id, // patient_ID
            tab_form_submission_id: tab_form_submission_id,
            entry_user_ID: entry_user_ID,
            entry_user_name: entry_user_name,
        };
        const req: any = {
            operation: 'createClinicalDataTabs',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    updateClinicalDataTabs(
        id,
        tab_form_submission_id,
        entry_user_ID,
        entry_user_name,
        tab_indexes
    ): any {
        let dataToSend = {
            id: id,
            tab_form_submission_id: tab_form_submission_id,
            entry_user_ID: entry_user_ID,
            entry_user_name: entry_user_name,
            tab_indexes: tab_indexes,
        };
        const req: any = {
            operation: 'updateClinicalDataTabs',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    getClinicalDataTabsByPatient(patient_ID): any {
        let dataToSend = {
            patient_ID: patient_ID,
        };
        const req: any = {
            operation: 'getClinicalDataTabsByPatient',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBClinicalData, req);
    }

    getRestrictedPatientList(sites: any[]): any {
        let dataToSend = {
            registry: this.configSvc.getTenant(),
            sites: sites,
        };
        const req: any = { operation: 'getRestrictedPatientList', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBPatient, req);
    }
}
