import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@h20-services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ParticipantService } from '@h20-services/participant.service';
import { ITask } from '@h20-services/models/tasks/ITask';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { UserStatus } from '@h20-services/models/status';
import { UserRole } from '@h20-services/models/role';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TaskStatus } from '@h20-services/models/status';
import { environment as env } from '@environment/environment';

@Component({
    selector: 'app-patient-todo',
    providers: [DatePipe],
    templateUrl: './patient-todo.component.html',
    styleUrls: ['./patient-todo.component.scss'],
})
export class PatientToDoComponent implements OnInit {
    taskCards: Array<{
        id: string;
        heading: string;
        subHeading: string;
        status: string;
        dueDate?: string;
        modified?: Date;
    }> = [];

    @Input() set tasks(tasks: ITask[] | null | undefined) {
        if (tasks) {
            this.processTasks(tasks);
        }
    }

    @Output() showConsentFormEvent = new EventEmitter<void>();
    loading = true;
    role: UserRole;
    currentParticipantId: string;
    isConsentRequired: boolean;
    tabs: { tabId: string; tabDisplay: string; badgeCount?: number }[];
    selectedTab: string;
    userClaims: [];
    badgeCount = 0;
    selectedDateFormat: string;
    selectedLanguage: string;
    selectedIndex = 0;

    IMG_URL_PREFIX = `${env.awsConfig.Path.defaultWebAssetPath}/files/empty-`;
    groupedUpcomingTasks;
    selectedTabTaskList;
    emptyStates = {
        active: {
            img: `${this.IMG_URL_PREFIX}active-task.png`,
            heading: 'PatientTask.NoActiveTasks',
            subHeading: 'PatientTask.AllSet',
        },
        complete: {
            img: `${this.IMG_URL_PREFIX}complete-task.png`,
            heading: 'PatientTask.AllCaughtUp',
            subHeading: 'PatientTask.AllComplete',
        },
        upcoming: {
            img: `${this.IMG_URL_PREFIX}upcoming-task.png`,
            heading: 'PatientTask.NoUpcoming',
            subHeading: 'PatientTask.NoUpcomingMsg',
        },
        expired: {
            img: `${this.IMG_URL_PREFIX}expired-task.png`,
            heading: 'PatientTask.NoExpired',
            subHeading: 'PatientTask.RightOnTrack',
        },
    };

    constructor(
        private auth: AuthService,
        public translate: TranslateService,
        private prtSvc: ParticipantService,
        private datePipe: DatePipe,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.selectedTab = 'active';
        this.getTabs();
        this.updateBadgeCount();
        this.selectedTabTaskList = this.getFilteredTaskCards();
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            if (pulseAuth) {
                this.currentParticipantId =
                    this.prtSvc.getCurrentParticipant() || pulseAuth.getUserID();

                const status = pulseAuth.status as UserStatus;
                this.role = pulseAuth.getType();
                this.selectedDateFormat = pulseAuth.getPreferredDateFormat();
                this.selectedLanguage = pulseAuth.getPreferredLang();
                this.isConsentRequired = [UserStatus.Consent, UserStatus.MigratedConsent].includes(
                    status
                );
                // Hiding upcoming tab temporarily:
                // this.groupUpcomingTasks();
            }
        });
    }

    groupUpcomingTasks(): void {
        const upcomingTasks = this.taskCards.filter((task) => task.status === 'upcoming');

        const groupedTasks = upcomingTasks.reduce(
            (groups, task) => {
                const date = task.dueDate?.split('T')[0];
                if (date) {
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(task);
                }
                return groups;
            },
            {} as { [date: string]: typeof this.taskCards }
        );

        this.groupedUpcomingTasks = Object.keys(groupedTasks).map((date) => ({
            date,
            tasks: groupedTasks[date],
        }));
    }

    getTabs() {
        this.tabs = [
            {
                tabId: 'active',
                tabDisplay: 'PatientTask.ActiveTasks',
                badgeCount: this.badgeCount,
            },
            { tabId: 'complete', tabDisplay: 'PatientTask.Complete' },
            // Hiding upcoming & expired tab temporarily:
            // { tabId: 'upcoming', tabDisplay: 'PatientTask.Upcoming' },
            // { tabId: 'expired', tabDisplay: 'PatientTask.Expired' },
        ];
    }

    processTasks(tasks: ITask[] | null | undefined): void {
        if (!tasks || tasks.length === 0) {
            this.taskCards = [];
            this.loading = false;
            return;
        }
        this.taskCards = tasks.map((task) => ({
            id: task.id,
            heading: this.getString(task.label),
            subHeading: this.getString(task.message),
            status: task.status,
            dueDate: task.due_date || null,
            modified: new Date(task.modified),
            task_configuration: task.task_configuration,
        }));
        this.sortTaskCards();
        this.selectedTabTaskList = this.getFilteredTaskCards();
    }

    getString(label: any): string {
        this.loading = false;
        if (typeof label === 'string') {
            try {
                const parsed = JSON.parse(label);
                if (parsed && typeof parsed === 'object') {
                    return parsed[this.selectedLanguage] ?? parsed['default'] ?? '';
                }
            } catch (error) {
                return label;
            }
        }
        if (typeof label === 'object' && label !== null) {
            return label[this.selectedLanguage] ?? label['default'] ?? '';
        }
        return '';
    }

    sortTaskCards(): void {
        const today = new Date();
        this.taskCards.sort((a, b) => {
            if (!a.dueDate) return 1;
            if (!b.dueDate) return -1;

            const dateA = new Date(a.dueDate);
            const dateB = new Date(b.dueDate);

            const dueDateDiff =
                Math.abs(dateA.getTime() - today.getTime()) -
                Math.abs(dateB.getTime() - today.getTime());

            if (dueDateDiff !== 0) {
                return dueDateDiff;
            }

            const statusOrder = { opened: 1, new: 2, completed: 3, expired: 4 };
            return statusOrder[a.status] - statusOrder[b.status];
        });
    }

    updateBadgeCount() {
        const activeTasks = this.taskCards.filter((task) =>
            ['new', 'opened'].includes(task.status)
        );
        this.badgeCount = activeTasks.length;
        this.updateTabs();
    }

    updateTabs() {
        this.tabs = this.tabs.map((tab) =>
            tab.tabId === 'active' ? { ...tab, badgeCount: this.badgeCount } : tab
        );
    }

    getFilteredTaskCards() {
        switch (this.selectedTab) {
            case 'active':
                this.updateBadgeCount();
                return this.taskCards.filter((task) => ['new', 'opened'].includes(task.status));
            case 'complete':
                return this.taskCards.filter((task) => task.status === 'completed');
            case 'upcoming':
                return this.taskCards.filter((task) => task.status === 'upcoming');
            case 'expired':
                return this.taskCards.filter((task) => task.status === 'expired');
            default:
                return [];
        }
    }

    handleTaskBtnClick(task: any): void {
        if (task.status === TaskStatus.Completed) {
            this.router.navigate(['patients', 'task', task.id]);
            return;
        }
        this.router.navigate(['surveys', task.task_configuration, 'task', task.id]);
    }

    onTabSelected(tabId: string): void {
        this.selectedTab = tabId;
        this.selectedTabTaskList = this.getFilteredTaskCards();
    }

    upcomingdateString(dueDate: string): string {
        this.selectedDateFormat = this.selectedDateFormat.replace(/mm/g, 'MM');
        const formattedDate = this.datePipe.transform(dueDate, this.selectedDateFormat) || '';
        return `${this.translate.instant('PatientTask.Upcoming')}: ${formattedDate}`;
    }
}
