import { Injectable } from '@angular/core';
import { StorageMap, JSONSchema } from '@ngx-pwa/local-storage';
import { AuthService } from './auth.service';
import { CDNService } from './cdn.service';
import { UuidService } from './uuid.service';
import { environment as env } from '@environment/environment';
import { Observable, lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { RequestService } from './request.service';
import { PulseAuth } from './models/PulseAuth';
import { CommonService } from './common.service';

export const listSchema: JSONSchema = {
    type: 'object',
    properties: {
        id: { type: 'string' },
        title: { type: 'string' },
    },
};

@Injectable({
    providedIn: 'root',
})
export class SurveyService {
    constructor(
        public storage: StorageMap,
        public auth: AuthService,
        private cdn: CDNService,
        private uuid: UuidService,
        private configsvc: ConfigService,
        private reqSvc: RequestService,
        private uuidsvc: UuidService,
        private commonSvc: CommonService
    ) {}

    surveys$ = this.getSurveys();
    prepopulateElements = { elements: [] };
    getSurveys(selectedEnv?: any): Observable<any> {
        const targetEnv = selectedEnv ? this.commonSvc.getTargetEnv(selectedEnv) : env;
        //If id is emtpy, return all surveys
        let dataToSend = {
            id: '',
        };

        const req: any = { operation: 'get', data: dataToSend };
        return this.reqSvc.post(targetEnv.API.writeToDBSurvey, req);
    }

    getSurveysByRegistry(registry?): any {
        let dataToSend = {
            registryId: registry || this.configsvc.getTenant(),
        };

        const req: any = {
            operation: 'getSurveysByRegistry',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    getSurveyTitleByIds(ids): any {
        let dataToSend = {
            surveyIds: ids,
        };

        const req: any = {
            operation: 'getSurveyTitleByIds',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    getScoreSummary(survey_submission_ID: string): Observable<any> {
        const dataToSend = {
            operation: 'getSurveySummary',
            survey_submission_ID: survey_submission_ID,
        };

        return this.reqSvc.post(env.API.scoringSubmission, dataToSend);
    }

    getScoreSummaryPBC40(survey_submission_ID): Observable<any> {
        var dataToSend = {
            operation: 'getPBC40Scoring',
            content: { survey_submission_ID: survey_submission_ID },
            registry: this.configsvc.getTenant(),
        };

        return this.reqSvc.post(env.API.scoringSubmission, dataToSend);
    }

    getSurveyScoreSection(survey_submission_ID: string): any {
        var dataToSend = {
            survey_submission_ID: survey_submission_ID,
            operation: 'getSurveySectionResponse',
            content: {
                survey_submission_ID: survey_submission_ID,
            },
        };

        return this.reqSvc.post(env.API.scoringSubmission, dataToSend);
    }

    watchSurveys$(): any {
        return this.storage.watch('surveys', listSchema);
    }

    pullSurveySubmission(
        survey_submission_ID: string,
        user_id: string,
        survey_ID: string,
        hasRestrictedData: boolean = false
    ) {
        let dataToSend = {
            survey_submission_ID: survey_submission_ID,
            user_id: user_id,
            survey_ID: survey_ID,
            registry_name: this.configsvc.getTenant(),
            hasRestrictedData: hasRestrictedData,
        };

        const req: any = {
            operation: 'pullSurveySubmission',
            data: dataToSend,
        };

        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    getSurveyById(id: string): Observable<any> {
        const req: any = {
            operation: 'get',
            data: {
                id: id,
            },
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    getSurveyById_Builder(domain: any, survey_id: any): Observable<any> {
        let dataToSend = {
            id: survey_id,
        };

        const req: any = {
            operation: 'get',
            data: dataToSend,
            bucket: env.awsConfig.Storage.S3.bucket,
            filename: `${domain}/surveys/${survey_id}.json`,
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    newSurvey(): any {
        const survey = {
            id: String(Math.random() * 1000),
            title: 'New Survey',
        };
        this.storage.set(survey.id, survey).subscribe(() => {});
        this.storage.get('surveys').subscribe((result: any) => {
            if (!result) {
                result = {};
            }
            result[survey.id] = survey;
            this.storage.set('surveys', result).subscribe(() => {});
        });
        return survey;
    }

    surveyStringify(survey: any): string {
        return JSON.parse(survey.replace(/^[\"]/, '').replace(/[\"]$/, ''));
    }

    saveSurvey(survey: any): Promise<any> {
        survey.pages.map((page: any) => {
            if (page.elements && page.elements.length > 0) {
                // TODO: check if panel
            }
        });
        survey.name = survey.name || survey.title;
        survey.version = '1';
        if (survey.pages) {
            survey.pages.map((pageEle) => {
                if (pageEle.elements) {
                    pageEle.elements.map((el) => {
                        if (el.elements) {
                            el.elements.map((e) => {
                                if (e.type == 'paneldynamic') {
                                    if (e.templateElements) {
                                        e.templateElements.map((element) => {
                                            if (element.prepopulate) {
                                                this.prepopulateElements.elements.push({
                                                    name: e.name,
                                                });
                                            }
                                        });
                                    }
                                } else {
                                    if (e.prepopulate) {
                                        this.prepopulateElements.elements.push({ name: e.name });
                                    }
                                }
                            });
                        } else {
                            if (el.prepopulate) {
                                this.prepopulateElements.elements.push({ name: el.name });
                            }
                        }
                    });
                }
            });
        }
        survey.domain = survey.domain || '';
        survey.registry = survey.registry || '';
        survey.json = JSON.stringify(survey);
        if (survey.id) {
            return this.updateSurvey(survey);
        } else {
            return this.createSurvey(survey);
        }
    }

    stringify(obj) {
        let cache = [];
        let str = JSON.stringify(obj, function (key, value) {
            if (typeof value === 'object' && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // Circular reference found, discard key
                    return;
                }
                // Store value in our collection
                cache.push(value);
            }
            return value;
        });
        cache = null; // reset the cache
        return str;
    }

    private assignSurveyDefaults(survey: any): void {
        survey.version = survey.version || '';
        survey.title = survey.title || '';
    }

    async createSurvey(survey: any): Promise<any> {
        this.assignSurveyDefaults(survey);
        return this.auth.getPulseAuth().then(async (pulseAuth: PulseAuth) => {
            const id = pulseAuth.getUserID();
            const variables = {
                ...survey,
                created_by_ID: id,
                modified_by_ID: id,
                prepopulate: JSON.stringify(this.prepopulateElements),
            };
            delete variables.pages;
            const req: any = { operation: 'create', data: variables };
            // return this.reqSvc.post(env.API.writeToDBSurvey, req);
            return await lastValueFrom(this.reqSvc.post(env.API.writeToDBSurvey, req));
        });
    }

    watchSurveyByID$(id: string): any {
        return this.storage.watch(id);
    }

    updateSurvey(survey: any): Promise<any> {
        this.assignSurveyDefaults(survey);
        return this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            const id = pulseAuth.getUserID();
            const variables = {
                ...survey,
                modified_by_ID: id,
                prepopulate: JSON.stringify(this.prepopulateElements),
            };
            delete variables.pages;
            const req: any = { operation: 'update', data: variables };
            return this.reqSvc.post(env.API.writeToDBSurvey, req);
        });
    }

    buildSurvey(creatorJSON): any {
        const survey = { ...this.surveyDefault, ...creatorJSON };
        return survey;
    }

    deleteSurvey(survey: any): any {
        const variables: any = { id: survey.id };
        const req: any = { operation: 'delete', data: variables };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    excludeFromReporting(survey_submission_ID: string, valFromReporting: string): any {
        const variables: any = {
            survey_submission_ID: survey_submission_ID,
            valFromReporting: valFromReporting,
        };
        const req: any = { operation: 'excludeFromReporting', data: variables };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    getPatientSurvey_ExcludeFromReporting(survey_submission_ID: any): any {
        let dataToSend = {
            survey_submission_ID: survey_submission_ID,
        };
        const req: any = {
            operation: 'getExcludeFromReporting',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    surveyDefault(): any {
        return {
            version: '',
            title: '',
        };
    }

    listSurveysByPatient(patientId: string) {
        const req: any = {
            operation: 'listSurveysByPatient',
            data: { patient_id: patientId },
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    getSubmissionByPatientSurvey(patientId: string, surveyId: string) {
        const req: any = {
            operation: 'getSubmissionByPatientSurvey',
            data: { patient_id: patientId, survey_id: surveyId },
        };
        return this.reqSvc.post(env.API.getSurveySubmission, req);
    }

    initializeSurvey(submission: any) {
        const req: any = {
            operation: 'initializeSurveyInstance',
            data: submission,
        };
        return this.reqSvc.post(env.API.surveySubmissionHandler, req);
    }

    viewSurveySubmission(submission: any) {
        const req: any = {
            operation: 'viewSurveySubmission',
            data: submission,
        };
        return this.reqSvc.post(env.API.getSurveySubmission, req);
    }

    submitPartialSurvey(submission: any): any {
        const req = {
            operation: 'submitPartialSurvey',
            data: submission,
        };

        return this.reqSvc
            .post(env.API.surveySubmissionHandler, req)
            .toPromise()
            .catch((e) => {
                throw e;
            });
    }

    submitSurvey(submission: any): any {
        const req = {
            operation: 'submitSurvey',
            data: submission,
        };

        return this.reqSvc
            .post(env.API.surveySubmissionHandler, req)
            .toPromise()
            .catch((e) => {
                throw e;
            });
    }

    //Todo: temporarily use this to update patient survey data
    saveSurveyToDb(response: any): any {
        response['registry'] = this.configsvc.getTenant();

        const req = {
            content: response,
        };

        // Send survey response to WriteToDblambda
        return this.reqSvc
            .post(env.API.writeToDb, req)
            .toPromise()
            .catch((e) => {
                throw e;
            });
    }

    deleteSubmission(submission: any): any {
        const req = {
            operation: 'deleteSubmission',
            data: submission,
        };
        return this.reqSvc
            .post(env.API.surveySubmissionHandler, req)
            .toPromise()
            .catch((e) => {
                throw e;
            });
    }

    submitAnonymousSurvey(submission: any): any {
        const req = {
            operation: 'submitAnonymousSurvey',
            data: submission,
        };

        return this.reqSvc
            .post(env.API.writeToDBSurvey, req)
            .toPromise()
            .catch((e) => {
                throw e;
            });
    }

    getSurveysByIds(surveyIds): any {
        let dataToSend = {
            surveyIds: surveyIds,
        };

        const req: any = {
            operation: 'getSurveysByIds',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    getSurveysByIds_Site(surveyIds, site): any {
        let dataToSend = {
            surveyIds: surveyIds,
        };

        const req: any = {
            operation: 'getSurveysByIds',
            data: dataToSend,
        };
        return this.reqSvc.post(site.API.writeToDBSurvey, req, site);
    }

    getSurveys_DeployVersion(site: any): Observable<any> {
        //If id is emtpy, return all surveys
        let dataToSend = {
            id: '',
        };
        const req: any = { operation: 'get', data: dataToSend };
        return this.reqSvc.post(site.API.writeToDBSurvey, req, site);
    }

    getSurveyById_Deploy(id: string, site: any) {
        let dataToSend = {
            id: id,
        };
        const req: any = { operation: 'get', data: dataToSend };
        return this.reqSvc.post(site.API.writeToDBSurvey, req, site);
    }

    saveSurvey_DeployVersion(survey: any, deploySite: any) {
        if (survey && survey.pages) {
            survey.pages.map((page: any) => {
                if (page.elements && page.elements.length > 0) {
                    // TODO: check if panel
                }
            });
        }
        survey.name = survey.name || survey.title;
        survey.version = '1';
        survey.domain = survey.domain || '';
        survey.prepopulate = survey.prepopulate || JSON.stringify(this.prepopulateElements);
        if (survey.deploy_mode && survey.deploy_mode === 'create') {
            return this.createSurvey_DeployVersion(survey, deploySite);
        } else {
            return this.updateSurvey_DeployVersion(survey, deploySite);
        }
    }

    updateSurvey_DeployVersion(survey: any, site: any) {
        this.assignSurveyDefaults(survey);
        const variables = {
            ...survey,
            modified_by_ID: '',
        };
        delete variables.pages;
        const req: any = { operation: survey.deploy_mode, data: variables };
        return this.reqSvc.post(site.API.writeToDBSurvey, req, site);
    }

    createSurvey_DeployVersion(survey: any, deploySite: any) {
        this.assignSurveyDefaults(survey);
        const variables = {
            ...survey,
            created_by_ID: 'xxx000222',
            modified_by_ID: 'xxx000222',
        };
        delete variables.pages;
        const req: any = { operation: 'create', data: variables };
        return this.reqSvc.post(deploySite.API.writeToDBSurvey, req);
    }

    getSurveysByDomain(domain: any, sourceSite: any): any {
        let dataToSend = {
            domainId: domain,
        };

        const req: any = {
            operation: 'getSurveysByDomain',
            data: dataToSend,
        };
        return this.reqSvc.post(sourceSite.API.writeToDBSurvey, req, sourceSite);
    }

    //save S3
    saveSurvey_Builder(survey: any): Promise<any> {
        survey.pages.map((page: any) => {
            if (page.elements && page.elements.length > 0) {
                // TODO: check if panel
            }
        });

        survey.name = survey.name || survey.title;
        survey.version = '1';
        survey.domain = survey.domain || '';
        survey.json = JSON.stringify(survey);
        this.assignSurveyDefaults(survey);
        return this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            const id = pulseAuth.getUserID();
            let sv = JSON.parse(survey.json);
            const req = {
                filename: `${sv.domain.toLowerCase()}/surveys/${survey.id}.json`,
                content: sv,
            };
            return this.reqSvc.post(env.API.writeToBucketURL, req);
        });
    }

    createAnonSurveyToken(): Promise<any> {
        return this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            const id = pulseAuth.getUserID();
            const req = {
                operation: 'createToken',
                data: {
                    user_ID: id,
                },
            };
            return this.reqSvc
                .post(env.API.anonymousSurveyHandler, req)
                .toPromise()
                .then((token) => {
                    return token;
                });
        });
    }

    concatenateSurveys(title: string, surveys: any[]): any {
        let newSurvey = {
            title: title,
            pages: [
                {
                    name: 'page1',
                    elements: [],
                },
            ],
        };
        for (let i = 0; i < surveys.length; i++) {
            const survey = surveys[i];
            if (
                survey &&
                survey.hasOwnProperty('pages') &&
                survey.pages[0] &&
                survey.pages[0].hasOwnProperty('elements')
            ) {
                const panels = survey.pages[0].elements;
                for (let j = 0; j < panels.length; j++) newSurvey.pages[0].elements.push(panels[j]); //add the panels
            }
        }
        return newSurvey;
    }
    listFormOptions(patientId: any, surveyId: any, table: any): Observable<any> {
        const req = {
            operation: 'listFormOptions',
            data: {
                user_id: patientId,
                survey_id: surveyId,
                table: table,
            },
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    appendAttestation(attestation: {
        submitter_id: string;
        submission_id: string;
        attested: boolean;
        comment: string;
        pi: string;
        pi_site_id: string;
    }) {
        const req = {
            operation: 'appendAttestation',
            data: { attestation },
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    updateTaskSubmissionId(
        taskId: string,
        submissionId: string,
        patientId: string
    ): Observable<any> {
        const req = {
            operation: 'updateTaskSubmissionId',
            data: {
                task_id: taskId,
                submission_id: submissionId,
                patient_id: patientId,
            },
        };
        return this.reqSvc.post(env.API.writeToDBSurvey, req);
    }

    updateSurveyMetadata(registryId: string, surveyId: string, targetEnv: any): Observable<any> {
        const req = {
            registryId: registryId,
            surveyId: surveyId,
        };
        return this.reqSvc.post(targetEnv.API.vocabularyConfiguration, req, targetEnv);
    }

    async updateQuestionKeyMapping(
        registryId: string,
        surveyId: string,
        elementList: any,
        targetEnv: any
    ): Promise<any> {
        const req = {
            operation: 'updateQuestionKeyMapping',
            registryId: registryId,
            surveyId: surveyId,
            elementList: elementList,
        };
        return await lastValueFrom(
            this.reqSvc.post(targetEnv.API.surveyQuestionKeyMappingHandler, req, targetEnv)
        );
    }

    async getRegistriesBySurveyId(surveyId: string, targetEnv: any): Promise<any> {
        const req = {
            operation: 'getRegistriesBySurveyId',
            surveyId: surveyId,
        };
        return await lastValueFrom(
            this.reqSvc.post(targetEnv.API.surveyQuestionKeyMappingHandler, req, targetEnv)
        );
    }

    async getSurveyQKeyMappingData(
        registryId: string,
        surveyId: string,
        targetEnv: any
    ): Promise<any> {
        const req = {
            operation: 'getSurveyQKeyMappingData',
            registryId: registryId,
            surveyId: surveyId,
        };
        return await lastValueFrom(
            this.reqSvc.post(targetEnv.API.surveyQuestionKeyMappingHandler, req, targetEnv)
        );
    }
}
