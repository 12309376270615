<!-- Compare Modal -->
<div class="container-fluid panel-control">
    <app-modal
        [footerText]=""
        [saveBtnText]="deployCompare ? 'Deploy Configs' : undefined"
        [showSaveBtn]="deployCompare"
        *ngIf="showCompare"
        [(visible)]="showCompare"
        [header]="'Compare Config'"
        [context]="this"
        [saveFunc]="deployCompare ? deployItems : undefined"
        [closeFunc]="closeCompareModal"
        [modalWidth]="'80vw'"
        [modalHeight]="'80vh'"
        [centerModalHorizontal]="false"
    >
        <div style="height: 100%">
            <div class="nav nav-tabs">
                <div class="nav-item" *ngFor="let item of itemSelection; let i = index">
                    <a
                        [href]="'#nav-' + i"
                        [attr.aria-controls]="'nav-' + i"
                        class="nav-link"
                        [ngClass]="{ active: i === 0 }"
                        [id]="'nav-' + i + '-tab'"
                        data-bs-toggle="tab"
                        role="tab"
                        [attr.aria-selected]="i === 0 ? 'true' : 'false'"
                    >
                        {{ getTruncatedValue(item, selectedTable.partitionKeyName) }}
                    </a>
                </div>
            </div>
            <div class="tab-content" id="nav-tabContent">
                <div
                    *ngFor="let item of itemSelection; let i = index"
                    [id]="'nav-' + i"
                    class="tab-pane"
                    [ngClass]="{ 'active show': i === 0 }"
                    role="tabpanel"
                    [attr.aria-labelledby]="'nav-' + i + '-tab'"
                >
                    <app-compare-deploy-config
                        [selectedItemKey]="item[selectedTable.partitionKeyName]"
                        [selectedTable]="selectedTable"
                    ></app-compare-deploy-config>
                </div>
            </div>
        </div>
    </app-modal>

    <!-- Filter -->
    <app-tray [visible]="toggleFilters" [trayTitle]="trayTitle" (innerToggle)="innerToggle($event)">
        <div class="trayInsert">
            <div>
                <div class="mx-3">
                    <label class="w-100"
                        >Key
                        <input
                            placeholder="primary key"
                            type="text"
                            class="form-control"
                            (change)="getSearchKey($event.target.value)"
                    /></label>
                </div>
                <app-button
                    class="m-2"
                    (action)="filterConfigItems()"
                    [buttonText]="'Library.Search'"
                />
            </div>
        </div>
    </app-tray>
    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h1 class="text-center mt-3 mb-3">Manage Config</h1>
                    <app-loading-component
                        [loading]="configsLoading"
                        [text]="'loading config tables'"
                    >
                        <div class="ms-3 d-flex">
                            <div class="mx-3">
                                <label>
                                    Tables in {{ sourceEnv }}:
                                    <select
                                        (change)="loadConfigItems($event.target.value)"
                                        class="form-select"
                                    >
                                        <option selected disabled>Pick a table</option>
                                        <option
                                            *ngFor="let config of configTables"
                                            [selected]="config.tableKey === selectedTable.tableKey"
                                        >
                                            {{ config.tableKey }}
                                        </option>
                                    </select>
                                </label>
                            </div>
                            <div
                                class="actions-container"
                                *ngIf="selectedTable.tableKey && selectedTable.tableKey !== ''"
                            >
                                <app-button
                                    *ngIf="canEdit"
                                    class="m-2"
                                    (action)="openConfigItemDetail('create')"
                                    [buttonText]="'Registry.CreateNew'"
                                />
                                <app-button
                                    class="m-2"
                                    (action)="deployItems()"
                                    [buttonText]="'Registry.DeployConfigs'"
                                />
                                <app-button
                                    class="m-2"
                                    (action)="showCompareModal()"
                                    [buttonText]="'Registry.Compare'"
                                />
                            </div>
                        </div>
                    </app-loading-component>
                    <div *ngIf="message" class="text-center text-danger mb-2">
                        {{ message }}
                    </div>
                    <div
                        *ngIf="showResultMsg && resultMsgList.length !== 0"
                        class="text-center mb-2"
                    >
                        Deploy Result
                        <div *ngFor="let msg of resultMsgList">
                            {{ msg }}
                            <br />
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="ms-1 d-flex"></div>
                    </div>
                    <hr />
                    <app-loading-component
                        [loading]="configItemsLoading"
                        [text]="'loading config items'"
                    >
                        <div class="ms-3 d-flex">
                            <div class="mx-3">Number of Item: {{ numberOfItems }}</div>
                        </div>

                        <app-table-component
                            *ngIf="
                                selectedTable.tableKey &&
                                selectedTable.tableKey !== '' &&
                                configItems
                            "
                            [rowData]="rows"
                            [columnDefs]="colDefs"
                            [autoHeight]="false"
                            rowSelection="multiple"
                            (selectionChanged)="onSelect($event)"
                            pagination="true"
                            [wrapText]="false"
                            [showFilterRow]="false"
                        ></app-table-component>
                    </app-loading-component>
                    <app-loading-component
                        [loading]="allVersionLoading"
                        [text]="'Loaging all versions'"
                    ></app-loading-component>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal
    [footerText]=""
    [saveBtnText]=""
    *ngIf="showView"
    [(visible)]="showView"
    [header]
    [context]="this"
    [saveFunc]=""
    [closeFunc]="closeViewModal"
    [modalWidth]="'40vw'"
    [modalHeight]="'70vh'"
>
    <app-manage-config-detail [Viewparam]="sendChild"> </app-manage-config-detail>
</app-modal>
