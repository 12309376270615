<div class="card mb-3">
    <div class="card-body">
        <h2 class="title">{{ 'Registry.PatientTask' | translate }}</h2>
        <app-horizontal-tab
            [tabs]="tabs"
            [selectedTab]="selectedTab"
            (tabSelected)="onTabSelected($event)"
        />
        <app-loading-component [loading]="loading">
            <div *ngIf="taskCards.length > 0">
                <div *ngIf="selectedTabTaskList.length > 0">
                    <div *ngIf="selectedTab === 'upcoming'">
                        <div *ngFor="let group of groupedUpcomingTasks">
                            <div class="date-heading">
                                {{ upcomingdateString(group.date) }}
                            </div>
                            <div *ngFor="let taskCard of group.tasks">
                                <app-task-card
                                    [heading]="taskCard.heading"
                                    [subHeading]="taskCard.subHeading"
                                    [status]="taskCard.status"
                                    [dueTime]="taskCard.dueDate"
                                    (viewCard)="handleTaskBtnClick(taskCard)"
                                >
                                </app-task-card>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedTab !== 'upcoming'">
                        <div *ngFor="let taskCard of selectedTabTaskList">
                            <div *ngIf="taskCard.status !== 'upcoming'">
                                <app-task-card
                                    [heading]="taskCard.heading"
                                    [subHeading]="taskCard.subHeading"
                                    [status]="taskCard.status"
                                    [dueTime]="taskCard.dueDate"
                                    [modified]="taskCard.modified"
                                    (viewCard)="handleTaskBtnClick(taskCard)"
                                >
                                </app-task-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedTabTaskList.length === 0 && !loading" class="no-tasks">
                <div class="empty-tasks">
                    <img class="no-task-img" alt="" [src]="emptyStates[selectedTab]?.img" />
                    <div class="empty-task-heading">
                        {{ emptyStates[selectedTab]?.heading | translate }}
                    </div>
                    <div class="empty-task-subHeading">
                        {{ emptyStates[selectedTab]?.subHeading | translate }}
                    </div>
                </div>
            </div>
        </app-loading-component>
    </div>
</div>
