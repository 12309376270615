import { Component, Injector, OnInit, Renderer2 } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { DomSanitizer } from '@angular/platform-browser';
import { IFormGestureMap } from '@h20-services/models/forms/types/IFormGestureMap';
import { UuidService } from '@h20-services/uuid.service';

import { environment as env } from '@environment/environment';
@Component({
    selector: 'app-pi-control-gesture-map',
    templateUrl: './pi-control-gesture-map.component.html',
    styleUrls: ['./pi-control-gesture-map.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlGestureMapComponent,
        },
    ],
})
// TODO: Add tab indexing and accessibility for this component.
export class PiControlGestureMapComponent
    extends AbstractPiControlComponent<IFormGestureMap, string>
    implements OnInit
{
    areas = [];
    imageUrl: string;
    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        public sanitizer: DomSanitizer
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    selectedAreas = [];

    ngOnInit(): void {
        this.imageUrl = `${env.awsConfig.Path.defaultWebAssetPath}/${this.element.imageurl}`;
        this.areas = JSON.parse(this.element.map);
        if (this.element.vocabularySourceUnique) {
            this.formControl.addValidators(this.uniqueVocabularyValidator());
        }
    }

    clickArea(areaId: string) {
        const areaEl = document.getElementById(areaId);
        areaEl.classList.toggle('overlay-poly-selected');
        this.formControl.setValue(areaId);
        this.markAsTouched();
        this._value = this.formControl.value;
        this.notifyValueChange();
    }

    uniqueVocabularyValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (
                this.element.vocabularySourcePicked &&
                this.element.vocabularySourcePicked.includes(String(control.value))
            )
                return {
                    notUnique: { value: 'This selection was already entered on another form.' },
                };
            return null;
        };
    }
}
