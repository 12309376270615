<div class="appPageTray p-3" [class.appPageTrayShow]="visible" #pageTray>
    <div class="d-flex align-items-center justify-content-between">
        <h3 class="truncate">{{ trayTitle }}</h3>
        <div>
            <ng-content select=".trayExtraButtons"></ng-content>
            <i
                class="far fa-arrow-circle-right toggleSwitch"
                (click)="toggle()"
                aria-label="Close Tray"
                title="Close Tray"
                tooltipStr="toggleTooltip"
                [options]="tooltipOptions"
                placement="left"
            ></i>
        </div>
    </div>
    <ng-content select=".trayInsert"></ng-content>
</div>
