export enum UserStatus {
    New = 'new',
    Pending = 'pending',
    Consent = 'consent',
    MigratedConsent = 'migrated-consent',
    Assent = 'assent',
    Declined = 'declined',
    Active = 'active',
    MigratedActive = 'migrated-active',
    Withdrawn = 'withdrawn',
    HardDeleted = 'hard-deleted',
    Inactive = 'inactive',
    ConfirmingEligibility = 'confirming-eligibility',
    Greenlight = 'greenlight',
}

export enum TaskStatus {
    New = 'new',
    Pending = 'pending',
    Expired = 'expired',
    Completed = 'completed',
    Opened = 'opened',
    Upcoming = 'upcoming',
}

export function IsTaskOpen(status: TaskStatus): boolean {
    const Open = [TaskStatus.New, TaskStatus.Opened];
    return Open.includes(status);
}

export enum PatientStatus {
    Deceased = 'deceased',
}
