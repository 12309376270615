<div class="todo-card row mt-2 w-100" tabindex="0" [ngClass]="{ disabled: disable }"
    (click)="status !== 'expired' ? onClick() : ''" (keydown)="handleKeydown($event)">
    <div class="card-left-line" [style.backgroundColor]="barColor"></div>
    <div class="todo-container">
        <div class="todo-div">
            <div class="icon-wrapper">
                <app-featured-icon class="col-24 main-icon" [iconName]="iconName" [size]="'large'" [type]="type"
                    [rounded]="true" />
            </div>
        </div>
        <div class="todo-div">
            <div class="heading-container">
                <h6 class="headingStyle">
                    {{ heading | translate }}
                </h6>
                <app-generic-badge class="d-flex align-items-center" [label]="label" [size]="'md'" [color]="color" />
            </div>
            <div class="subHeadingStyle mt-2">{{ subHeading | translate }}</div>
            <div *ngIf="dueTimeString">
                <div class="d-flex align-items-center time" [ngStyle]="{ color: dueTimeColor }">
                    <app-featured-icon [iconName]="'fa-regular fa-calendar'" [size]="'small'" [type]="'tertiary'" />
                    <div class="dueTimeStyle" [ngStyle]="{ color: dueTimeColor }">
                        {{ dueTimeString | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="todo-div">
            <app-button *ngIf="showCardButton" [btnWidth]="'90%'" [buttonText]="buttonText" [design]="buttonDesign"
                (action)="onClick()" [disabled]="disable" />
        </div>
    </div>
</div>