import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { AuthService } from '@h20-services/auth.service';
import { ToastService } from '@h20-shared/services/toast.service';
import { UserService } from '@h20-services/user.service';
import { fetchAuthSession } from '@aws-amplify/auth';

@Component({
    selector: 'app-date-time-setting',
    templateUrl: './date-time-setting.component.html',
    styleUrls: ['./date-time-setting.component.scss'],
})
export class DateTimeSettingComponent implements OnInit {
    selectedTimeFormat;
    selectedDateFormat;
    initialTimeFormat: string;
    tempTimeFormat: string;
    initialDateFormat: string;
    tempDateFormat: string;
    timeSubHeading = '';
    dateSubHeading = '';
    loading = true;

    constructor(
        public translate: TranslateService,
        private auth: AuthService,
        private userSvc: UserService,
        private toastService: ToastService
    ) {}

    ngOnInit() {
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            this.initialTimeFormat = this.selectedTimeFormat = pulseAuth.getPreferredTimeFormat();
            this.initialDateFormat = this.selectedDateFormat = pulseAuth.getPreferredDateFormat();
            this.initialTimeFormat = this.selectedTimeFormat;
            this.tempTimeFormat = this.selectedTimeFormat;
            this.initialDateFormat = this.selectedDateFormat;
            this.tempDateFormat = this.selectedDateFormat;
            this.setSubHeading();
            this.loading = false;
        });
    }

    handleDateTimeFormatChange(): void {
        const timeFormatChanged = this.initialTimeFormat !== this.tempTimeFormat;
        const dateFormatChanged = this.initialDateFormat !== this.tempDateFormat;

        if (!timeFormatChanged && !dateFormatChanged) {
            this.showToast({ type: 'error', body: 'UserSettings.DateTimeError' });
            return;
        }
        this.selectedTimeFormat = this.tempTimeFormat;
        this.selectedDateFormat = this.tempDateFormat;

        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            if (pulseAuth) {
                const payload: any = {
                    lang: this.translate.currentLang,
                };

                if (timeFormatChanged) {
                    payload.timeFormat = this.selectedTimeFormat;
                }

                if (dateFormatChanged) {
                    payload.dateFormat = this.selectedDateFormat;
                }

                this.userSvc
                    .saveUserSettings(pulseAuth.getUserID(), payload)
                    .toPromise()
                    .then(async () => {
                        try {
                            await fetchAuthSession({ forceRefresh: true });
                        } catch (err) {
                            console.error(err);
                        }
                        this.showToast({
                            type: 'success',
                            body: this.translate.instant('Toast.SettingsSuccessBody'),
                        });
                        this.initialTimeFormat = this.selectedTimeFormat;
                        this.initialDateFormat = this.selectedDateFormat;

                        this.setSubHeading();
                    })
                    .catch((error) => {
                        this.showToast({
                            type: 'error',
                            body: this.translate.instant('Toast.SettingsErrorBody'),
                        });
                    });
            }
        });
    }

    showToast(toast) {
        this.toastService.show({
            type: toast.type,
            body: this.translate.instant(toast.body),
        });
    }

    setSubHeading(): void {
        const timeFormatMessage = this.translate.instant('UserSettings.TimeFormatMessage');
        const time = this.translate.instant('UserSettings.Time');

        this.timeSubHeading = `
      ${timeFormatMessage} 
      <strong>${this.selectedTimeFormat.slice(0, -1)}</strong> 
      ${time}
    `;

        const dateFormatMessage = this.translate.instant('UserSettings.DateFormatMessage');
        const format = this.translate.instant('UserSettings.Format');
        this.dateSubHeading = `
      ${dateFormatMessage} 
      <strong>${this.selectedDateFormat.toUpperCase()}</strong> 
      ${format}
    `;
    }
}
