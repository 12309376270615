import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService } from '@h20-services/config.service';
import ExcelExport from 'export-xlsx';
import { DDBEmailTemplates } from '@h20-services/ddb-email.service';
import { ISESListResult } from '@h20-services/models/emails/ISESListResult';
import { TaskService } from '@h20-services/task.service';
import { SurveyService } from '@h20-services/survey.service';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { CDNService } from '@h20-services/cdn.service';
import { EnvironmentService } from '@h20-services/environment.service';
import { ComposerRoleService } from '@h20-services/composer-role.service';
import { Roles } from '@h20-services/enums/roles/roles';
import { ClaimService } from '@h20-services/claim.service';

const excelExport = new ExcelExport();

@Component({
    selector: 'app-translation-export',
    templateUrl: './translation-export.component.html',
    styleUrls: ['./translation-export.component.scss'],
})
export class TranslationExportComponent implements OnInit, OnDestroy {
    //#region Fields

    loading: boolean = true;
    loadingRegConfig: boolean = true;
    loadingSES: boolean = true;
    loadingTasks: boolean = true;
    loadingSurveys: boolean = true;
    loadingPlaformSTR: boolean = true;
    loadingExport: boolean;

    subscriptions: Subscription[] = [];
    userClaims: any;
    registries: any[];
    translationItems: string[] = [
        'Platform-Strings',
        'Registry-Configuration-Data',
        'Email-Templates',
        'Tasks',
        'Surveys',
    ];

    ItemDescriptions = {
        'Platform-Strings': 'Contains all translatable text strings displayed on the site.',
        'Registry-Configuration-Data':
            'Defines registry-specific configurations, including logos, branding colors, and names.',
        'Email-Templates': 'Provides templates for various email types used within the system.',
        Tasks: 'Represents task prototypes associated with a registry.',
        Surveys: 'Includes all surveys created for the selected registry.',
    };

    selectedRegistry: any;
    registryConfigurationOriginalTypes: any;
    registryConfigurationSelection: any;
    sectionItems: any;
    selectedRegistryFromSvc: string;

    sesTemplateItems: any[];
    sesTemplatePrefix: string[];
    sesTemplateSelection: any;

    taskList: any[];
    taskPrototypeSelection: any;

    allSurveyList: any[];
    surveyList: any[];
    surveySelection: any;

    platformStrings: any;
    platformStringsSelection: any;

    jsonEditorOptions: JsonEditorOptions;

    reg_site_mapping = {
        nkfpatientnetwork: [
            'empty',
            'alport',
            'geisinger',
            'nkf',
            'nkfwelcome',
            'nkfforgotpassword',
            'nkfwelcometest',
        ],
        'poland-syndrome': ['pip', 'poland'],
        cdcn: ['cdcn'],
        cdkl5: ['cdkl5'],
        fagpr: ['fagpr', 'fara'],
        birt: ['birt'],
        catnap: ['catnap'],
        curve: ['curve', 'san-diego-sex-med'],
        grasp: ['grasp'],
        asmd: ['asmd'],
        gmrn: ['gmrn'],
        paleos: ['paleos'],
        ccr: ['ccr', 'cde'],
        asfalportpatientregistry: ['asfalportpatientregistry', 'nkf', 'asf'],
        'proto-ui-copy': ['proto-ui-copy'],
        'disabled-grasp': ['disabled-grasp'],
        'disabled-curve': ['disabled-curve'],
        chapter: ['chapter', 'hfsh-detroit'],
        'proto-ui': ['proto-ui'],
        localhost: ['localhost'],
        default: ['default'],
        omni: ['omni'],
        afcl: ['afcl'],
        afcl2: ['afcl2'],
        composer: ['composer'],
    };

    //#endregion

    //#region Constructors
    constructor(
        private config_svc: ConfigService,
        private ddb_email_svc: DDBEmailTemplates,
        private task_svc: TaskService,
        private survey_svc: SurveyService,
        private cdn_svc: CDNService,
        private environmentSvc: EnvironmentService,
        private roleSvc: ComposerRoleService,
        private claimService: ClaimService
    ) {
        roleSvc.currentRole.subscribe(() => {
            this.userClaims = this.claimService.getUserClaims();
        });
        this.environmentSvc.currentRegistry.subscribe((environment) => {
            if (this.selectedRegistry) this.registryFilter(environment);
            else this.selectedRegistryFromSvc = environment;
        });
    }

    ngOnInit(): void {
        this.loading = true;
        this.loadingRegConfig = true;

        // Load registry_config Data
        const regSub$ = this.config_svc.listRegistryConfig().subscribe((registries) => {
            if (registries) {
                this.registries = [
                    {
                        registry_id: 'All',
                        languages: this.getAllLangs(registries),
                    },
                    ...registries,
                ];
                this.selectedRegistry = this.registries[0];

                this.buildSectionItems();
            }
            this.loadingRegConfig = false;
            this.doneLoading();
        });
        this.subscriptions.push(regSub$);

        this.loadSESTemplates();
        this.loadTaskPrototypes();
        this.loadSurveys();
        this.loadPlatformStrings();

        this.jsonEditorOptions = new JsonEditorOptions();
        this.jsonEditorOptions.mode = 'view';
    }

    ngOnDestroy(): void {
        this.subscriptions.map((sub) => {
            sub.unsubscribe();
        });
    }

    doneLoading() {
        this.loading =
            this.loadingRegConfig ||
            this.loadingSES ||
            this.loadingTasks ||
            this.loadingPlaformSTR ||
            this.loadingSurveys;

        if (!this.loading && this.selectedRegistryFromSvc) {
            this.registryFilter(this.selectedRegistryFromSvc);
        } else if (!this.loading) {
            this.registryFilter('All');
        }
    }

    loadSESTemplates(): void {
        this.loadingSES = true;
        const sesTemplateSub$ = this.ddb_email_svc.listTemplates().subscribe((templates: any) => {
            if (templates) {
                this.sesTemplateItems = templates;

                this.sesTemplatePrefix = templates.map((t) => {
                    return t.template_name?.split('-')[0].toUpperCase();
                });

                this.sesTemplatePrefix = [...new Set(this.sesTemplatePrefix)];
                this.loadingSES = false;
                this.doneLoading();
            }
        });
        this.subscriptions.push(sesTemplateSub$);
    }

    loadTaskPrototypes(): void {
        this.loadingTasks = true;
        const taskProtoSub$ = this.task_svc.listTaskPrototypes().subscribe((tasks: any) => {
            this.taskList = tasks;
            this.taskList.map((task) => {
                task.message = this.tryparsejson(task.message);
            });

            this.loadingTasks = false;
            this.doneLoading();
        });
        this.subscriptions.push(taskProtoSub$);
    }

    loadSurveys(): void {
        this.loadingSurveys = true;
        const surveysSub$ = this.survey_svc.getSurveys().subscribe((surveys: any) => {
            this.surveyList = surveys;
            this.allSurveyList = surveys;
            this.surveyList.map((i) => {
                try {
                    i.json = JSON.parse(i.json);
                } catch (err) {
                    i.json = {};
                }
            });

            this.loadingSurveys = false;
            this.doneLoading();
        });
        this.subscriptions.push(surveysSub$);
    }

    loadPlatformStrings(): void {
        this.loadingPlaformSTR = true;
        this.cdn_svc
            .listLangsFromDeployedS3Bucket()
            .toPromise()
            .then((res) => {
                if (res) {
                    let langs = res.map((r: string) => r.replace('assets/i18n/', '').split('.')[0]);
                    let platformStrings = [];
                    langs.map((lang) => {
                        platformStrings.push(
                            this.cdn_svc
                                .getLangFromDeployedS3Bucket(`${lang}.json`)
                                .toPromise()
                                .then((res) => {
                                    return { [lang]: JSON.parse(res) };
                                })
                        );
                    });

                    Promise.all(platformStrings).then((data) => {
                        this.platformStrings = {};
                        data.map((d) => {
                            this.platformStrings = {
                                ...this.platformStrings,
                                ...d,
                            };
                        });

                        this.loadingPlaformSTR = false;
                        this.doneLoading();
                    });
                }
            });
    }

    getAllLangs(registryList: any[]) {
        let reg_langs = [];
        registryList.map((item) => {
            if (item.languages) reg_langs.push(...JSON.parse(item.languages));
        });
        return JSON.stringify([...new Set(reg_langs)]);
    }

    buildSectionItems() {
        this.sectionItems = {};
        this.translationItems.map((item) => {
            this.sectionItems[item] = false;
        });

        this.registryConfigurationOriginalTypes = {};
        this.registries.map((r) => {
            Object.keys(r).map((k) => {
                this.registryConfigurationOriginalTypes[k] = typeof r[k];
            });
        });
    }

    //#endregion

    //#region UI Methods

    registryFilter(selectedReg) {
        this.surveyList = this.allSurveyList;
        if (selectedReg == 'All') {
            this.selectedRegistry = this.registries[0];
        } else {
            this.selectedRegistry = this.registries.find((reg) => reg.registry_id == selectedReg);
        }

        this.sesTemplateSelection = {};
        this.selectAllEmailTemplates(false);

        this.taskPrototypeSelection = {};
        this.selectAllTaskPrototypes(false);

        this.surveySelection = {};
        let items = this.surveyList.filter((s) => {
            let registryArray =
                this.reg_site_mapping[this.selectedRegistry.registry_id]?.map((item) =>
                    item.toLowerCase()
                ) || [];

            return (
                this.selectedRegistry.registry_id === 'All' ||
                (Array.isArray(s.registry) &&
                    s.registry.some(
                        (reg) =>
                            typeof reg === 'string' &&
                            reg.toLowerCase().includes(this.selectedRegistry.registry_id)
                    ))
            );
        });

        let surveyIds = [];
        items.map((el) => {
            surveyIds.push(el.id);
        });
        if (surveyIds.length > 0) {
            this.survey_svc.getSurveysByIds(surveyIds).subscribe((respone) => {
                this.surveyList = respone;
            });
        } else this.surveyList = [];
        this.selectAllSurveys(false);

        this.registryConfigurationSelection = {};
        this.selectAllRegistryConfigs(false);

        this.platformStringsSelection = {};
        this.selectAllPlatformStrings(false);
    }

    contentSelectionChanged(name, checked) {
        this.sectionItems[name] = checked;
    }

    listEmailTemplates() {
        let items = this.sesTemplateItems.filter((sestemp) => {
            return (
                this.selectedRegistry.registry_id == 'All' ||
                this.reg_site_mapping[this.selectedRegistry.registry_id].includes(
                    sestemp['registry_id#organization#site_id#template#language']
                        ?.split('#')[0]
                        .toLowerCase()
                )
            );
        });

        return items;
    }
    selectAllEmailTemplates(checked: boolean) {
        this.listEmailTemplates().map((k) => {
            this.sesTemplateSelection[k['registry_id#organization#site_id#template#language']] =
                checked;
        });
    }
    changeEmailTemplate(item, checked) {
        this.sesTemplateSelection[item] = checked;
    }

    listTaskPrototypes() {
        let items = this.taskList.filter(
            (t) =>
                this.selectedRegistry.registry_id == 'All' ||
                // this.reg_site_mapping[this.selectedRegistry.registry_id].includes(t.site_ID)
                this.selectedRegistry.registry_id == t.site_ID
        );

        return items;
    }
    selectAllTaskPrototypes(checked: boolean) {
        this.listTaskPrototypes().map((k) => {
            this.taskPrototypeSelection[k.id] = checked;
        });
    }
    changeTaskPrototype(item, checked) {
        this.taskPrototypeSelection[item] = checked;
    }

    listSurveys() {
        /*
        let items = this.surveyList.filter(
            (s) =>
                this.selectedRegistry.registry_id == 'All' ||
                this.reg_site_mapping[
                    this.selectedRegistry.registry_id
                ].includes(s.domain.toLowerCase())
        );
        
        return items;
        */
        return this.surveyList;
    }
    selectAllSurveys(checked: boolean) {
        this.listSurveys().map((k) => {
            this.surveySelection[k.id] = checked;
        });
    }
    changeSurveys(item, checked) {
        this.surveySelection[item] = checked;
    }

    listRegistryConfigKeys() {
        return Object.keys(this.selectedRegistry);
    }
    getRegistryConfigJson() {
        let keys = Object.keys(this.selectedRegistry);
        keys.map((k) => {
            try {
                if (typeof this.selectedRegistry[k] == 'string')
                    this.selectedRegistry[k] = JSON.parse(this.selectedRegistry[k]);
            } catch (err) {}
        });

        return this.selectedRegistry;
    }
    selectAllRegistryConfigs(checked: boolean) {
        this.listRegistryConfigKeys().map((k) => {
            this.registryConfigurationSelection[k] = checked;
        });
    }
    changeRegistryConfig(item, checked) {
        this.registryConfigurationSelection[item] = checked;
    }

    listPlatformStrings() {
        return Object.keys(this.platformStrings);
    }
    selectAllPlatformStrings(checked: boolean) {
        this.listPlatformStrings().map((k) => {
            this.platformStringsSelection[k] = checked;
        });
    }
    changePlatformStrings(item, checked: boolean) {
        this.platformStringsSelection[item] = checked;
    }

    //#endregion

    //#region Utils

    getText(strOrLangs): string {
        if (!strOrLangs) return '';
        return strOrLangs['en'] || strOrLangs['default'] || strOrLangs;
    }

    removeExcelWorksheetNameIllegalCharacters(name: string): string {
        return name.replace(/[\*\?\:\\\/\[\]]/gm, ' ');
    }

    tryparsejson(item) {
        try {
            return JSON.parse(item);
        } catch (err) {
            return item;
        }
    }

    transformFlatByLangKeys(flat: {}) {
        let re = {};
        Object.keys(flat).map((k) => {
            let spl = k.split('.');
            let lang = spl.splice(spl.length - 1, 1)[0];

            if (re.hasOwnProperty(spl.join('.'))) re[spl.join('.')][lang] = flat[k];
            else re[spl.join('.')] = { [lang]: flat[k] };
        });
        return re;
    }

    //#endregion

    //#region Excel Export Methods

    getEmailTemplateSheetOptions() {
        const sheetoptions = {
            tableTitle: 'Email Templates',
            headerGroups: [],
            headerDefinition: [
                {
                    name: 'TemplateName',
                    key: 'TemplateName',
                },
                {
                    name: 'SubjectPart',
                    key: 'SubjectPart',
                },
                {
                    name: 'TextPart',
                    key: 'TextPart',
                },
                {
                    name: 'HtmlPart',
                    key: 'HtmlPart',
                },
            ],
        };
        return {
            sheetName: 'Email Templates',
            startingRowNumber: 1,
            gapBetweenTwoTables: 2,
            tableSettings: {
                email_templates: sheetoptions,
            },
        };
    }
    getSelectedEmailTemplateData(useSelection = true): Promise<any> {
        let letResponses = [];

        if (useSelection)
            this.sesTemplateItems
                .filter((t) => this.sesTemplateSelection[t.template_name])
                .map((t) => {
                    letResponses.push(
                        this.ddb_email_svc
                            .getTemplate(t['registry_id#organization#site_id#template#language'])
                            .toPromise()
                            .then((temp: any) => {
                                if (temp) return temp.Template;
                                throw `Promise not finished for email template ${t.template_name}`;
                            })
                    );
                });
        else
            this.listEmailTemplates().map((t) => {
                letResponses.push(
                    this.ddb_email_svc
                        .getTemplate(t['registry_id#organization#site_id#template#language'])
                        .toPromise()
                        .then((temp: any) => {
                            if (temp) return temp.Template;
                            throw `Promise not finished for email template ${t.template_name}`;
                        })
                );
            });

        return Promise.all(letResponses).then((res) => {
            return res;
        });
    }
    buildEmailTemplateExport(): void {
        this.loadingExport = true;
        const file = {
            fileName: `custom_${this.selectedRegistry.registry_id}_EmailTemplate_translations`,
            workSheets: [this.getEmailTemplateSheetOptions()],
        };

        this.getSelectedEmailTemplateData().then((data: any) => {
            excelExport.downloadExcel(file, [{ email_templates: data }]);
            this.loadingExport = false;
        });
    }

    getTaskDatasource(useSelection) {
        if (useSelection)
            return [
                {
                    task_prototypes: this.taskList.filter((t) => this.taskPrototypeSelection[t.id]),
                },
            ];
        return [
            {
                task_prototypes: this.listTaskPrototypes(),
            },
        ];
    }
    transformTasks(tasks) {
        let langs = [];
        tasks[0].task_prototypes.map((task) => {
            if (typeof task.message == 'object') {
                langs.push(...Object.keys(task.message));
            } else {
                langs.push('default');
            }
        });
        tasks['langs'] = [...new Set(langs)];
        return tasks;
    }
    getTaskPrototypeSheetOptions(useSelection = true) {
        let headers = [
            {
                name: 'id',
                key: 'id',
            },
            {
                name: 'label',
                key: 'label',
            },
        ];
        let tasks = this.getTaskDatasource(useSelection);
        tasks = this.transformTasks(tasks);
        tasks['langs'].map((lang) => {
            headers.push({
                name: lang,
                key: lang,
            });
        });
        const sheetoptions = {
            tableTitle: 'Tasks',
            headerGroups: [],
            headerDefinition: headers,
        };
        return {
            sheetName: 'Tasks',
            startingRowNumber: 1,
            gapBetweenTwoTables: 2,
            tableSettings: {
                task_prototypes: sheetoptions,
            },
        };
    }
    buildTaskPrototypeData(useSelection = true) {
        let data = this.transformTasks(this.getTaskDatasource(useSelection));

        data[0].task_prototypes.map((task) => {
            data['langs'].map((lang) => {
                if (typeof task.message == 'object' && task.message[lang]) {
                    task[lang] = task.message[lang];
                } else if (lang == 'default') {
                    task[lang] = task.message;
                } else {
                    task[lang] = '';
                }
            });
            return task;
        });

        return data;
    }
    buildTaskPrototypeExport() {
        this.loadingExport = true;
        const file = {
            fileName: `custom_${this.selectedRegistry.registry_id}_Task_translations`,
            workSheets: [this.getTaskPrototypeSheetOptions()],
        };

        excelExport.downloadExcel(file, this.buildTaskPrototypeData());
        this.loadingExport = false;
    }

    getSurveyDatasource(useSelection) {
        if (useSelection) return this.surveyList.filter((t) => this.surveySelection[t.id]);
        return this.listSurveys();
    }
    /**
     *
     * @param survey (raw top level survey object already json parsed)
     * @returns :{question_key: element.title} || {question_key-choice_value: choice.text}
     */
    tranformSurvey(survey) {
        let survey_map = {};
        let langs = [];
        JSON.parse(survey.json).pages.map((page) => {
            if (!page.elements) return;
            page.elements.map((panel) => {
                if (!panel.elements) return;
                if (panel.title) {
                    survey_map[panel.name] = panel.title;
                }
                panel.elements.map((element) => {
                    survey_map[element.name] = element.title || element.html;
                    if (element.hasOwnProperty('choices')) {
                        element.choices.map((choice) => {
                            survey_map[`${element.name}-${choice.value}`] = choice.text;
                            if (typeof choice.text == 'object') {
                                langs.push(...Object.keys(choice.text));
                            }
                        });
                    } else if (element.hasOwnProperty('items')) {
                        element.items.map((item) => {
                            survey_map[item.name] = item.title;
                            if (typeof item.title == 'object') {
                                langs.push(...Object.keys(item.title));
                            }
                        });
                    }

                    if (element.hasOwnProperty('otherText')) {
                        let otherText = element['otherText'];
                        if (typeof otherText == 'object') {
                            langs.push(...Object.keys(otherText));
                        }
                        survey_map[`${element.name}-otherText`] = otherText;
                    }

                    if (element.tooltip) {
                        survey_map[element.name + '-tooltip'] = element.tooltip;
                    }

                    if (element.hasOwnProperty('validators')) {
                        let validators = element['validators'][0]?.text;
                        if (validators) {
                            if (typeof validators == 'object') {
                                langs.push(...Object.keys(validators));
                            }
                            survey_map[`${element.name}-validators`] = validators;
                        }
                    }

                    if (element.hasOwnProperty('minErrorText')) {
                        let minErrorText = element['minErrorText'];
                        if (typeof minErrorText == 'object') {
                            langs.push(...Object.keys(minErrorText));
                        }
                        survey_map[`${element.name}-minErrorText`] = minErrorText;
                    }

                    if (element.hasOwnProperty('maxErrorText')) {
                        let maxErrorText = element['maxErrorText'];
                        if (typeof maxErrorText == 'object') {
                            langs.push(...Object.keys(maxErrorText));
                        }
                        survey_map[`${element.name}-maxErrorText`] = maxErrorText;
                    }

                    if (element.hasOwnProperty('minRateDescription')) {
                        let minRateDescription = element['minRateDescription'];
                        if (typeof minRateDescription == 'object') {
                            langs.push(...Object.keys(minRateDescription));
                        }
                        survey_map[`${element.name}-minRateDescription`] = minRateDescription;
                    }

                    if (element.hasOwnProperty('maxRateDescription')) {
                        let maxRateDescription = element['maxRateDescription'];
                        if (typeof maxRateDescription == 'object') {
                            langs.push(...Object.keys(maxRateDescription));
                        }
                        survey_map[`${element.name}-maxRateDescription`] = maxRateDescription;
                    }

                    if (element.hasOwnProperty('requiredErrorText')) {
                        let requiredErrorText = element['requiredErrorText'];
                        if (typeof requiredErrorText == 'object') {
                            langs.push(...Object.keys(requiredErrorText));
                        }
                        survey_map[`${element.name}-requiredErrorText`] = requiredErrorText;
                    }

                    if (element.hasOwnProperty('description')) {
                        let description = element['description'];
                        if (typeof description == 'object') {
                            langs.push(...Object.keys(description));
                        }
                        survey_map[`${element.name}-description`] = description;
                    }
                    if (element.hasOwnProperty('preamble')) {
                        let preamble = element['preamble'];
                        if (typeof preamble == 'object') {
                            langs.push(...Object.keys(preamble));
                        }
                        survey_map[`${element.name}-preamble`] = preamble;
                    }

                    if (element.hasOwnProperty('subheading')) {
                        let subheading = element['subheading'];
                        if (typeof subheading == 'object') {
                            langs.push(...Object.keys(subheading));
                        }
                        survey_map[`${element.name}-subheading`] = subheading;
                    }

                    if (element.hasOwnProperty('footnote')) {
                        let footnote = element['footnote'];
                        if (typeof footnote == 'object') {
                            langs.push(...Object.keys(footnote));
                        }
                        survey_map[`${element.name}-subheading`] = footnote;
                    }

                    //dynamic panels
                    if (element.hasOwnProperty('templateElements')) {
                        element.templateElements.map((dyn_element) => {
                            if (dyn_element.title && typeof dyn_element.title == 'object') {
                                langs.push(...Object.keys(dyn_element.title));
                            }

                            if (dyn_element.hasOwnProperty('choices')) {
                                dyn_element.choices.map((choice) => {
                                    survey_map[
                                        `${element.name}-${dyn_element.name}-${choice.value}`
                                    ] = choice.text;
                                    if (typeof choice.text == 'object') {
                                        langs.push(...Object.keys(choice.text));
                                    }
                                });
                            } else if (dyn_element.hasOwnProperty('items')) {
                                dyn_element.items.map((item) => {
                                    survey_map[
                                        `${element.name}-${dyn_element.name}-${item.value}`
                                    ] = item.title;
                                    if (typeof item.title == 'object') {
                                        langs.push(...Object.keys(item.title));
                                    }
                                });
                            }

                            if (dyn_element.hasOwnProperty('otherText')) {
                                let otherText = dyn_element['otherText'];
                                if (typeof otherText == 'object') {
                                    langs.push(...Object.keys(otherText));
                                }
                                survey_map[`${element.name}-${dyn_element.name}-otherText`] =
                                    otherText;
                            }

                            if (dyn_element.tooltip) {
                                survey_map[`${element.name}-${dyn_element.name}-tooltip`] =
                                    dyn_element.tooltip;
                            }

                            if (dyn_element.hasOwnProperty('validators')) {
                                let validators = dyn_element['validators'][0]?.text;
                                if (validators) {
                                    if (typeof validators == 'object') {
                                        langs.push(...Object.keys(validators));
                                    }
                                    survey_map[`${element.name}-${dyn_element.name}-validators`] =
                                        validators;
                                }
                            }

                            if (dyn_element.hasOwnProperty('minErrorText')) {
                                let minErrorText = dyn_element['minErrorText'];
                                if (typeof minErrorText == 'object') {
                                    langs.push(...Object.keys(minErrorText));
                                }
                                survey_map[`${element.name}-${dyn_element.name}-minErrorText`] =
                                    minErrorText;
                            }

                            if (dyn_element.hasOwnProperty('maxErrorText')) {
                                let maxErrorText = dyn_element['maxErrorText'];
                                if (typeof maxErrorText == 'object') {
                                    langs.push(...Object.keys(maxErrorText));
                                }
                                survey_map[`${element.name}-${dyn_element.name}-maxErrorText`] =
                                    maxErrorText;
                            }

                            if (dyn_element.hasOwnProperty('requiredErrorText')) {
                                let requiredErrorText = dyn_element['requiredErrorText'];
                                if (typeof requiredErrorText == 'object') {
                                    langs.push(...Object.keys(requiredErrorText));
                                }
                                survey_map[
                                    `${element.name}-${dyn_element.name}-requiredErrorText`
                                ] = requiredErrorText;
                            }
                            if (dyn_element.hasOwnProperty('minRateDescription')) {
                                let minRateDescription = dyn_element['minRateDescription'];
                                if (typeof minRateDescription == 'object') {
                                    langs.push(...Object.keys(minRateDescription));
                                }
                                survey_map[
                                    `${element.name}-${dyn_element.name}-minRateDescription`
                                ] = minRateDescription;
                            }

                            if (dyn_element.hasOwnProperty('maxRateDescription')) {
                                let maxRateDescription = dyn_element['maxRateDescription'];
                                if (typeof maxRateDescription == 'object') {
                                    langs.push(...Object.keys(maxRateDescription));
                                }
                                survey_map[
                                    `${element.name}-${dyn_element.name}-maxRateDescription`
                                ] = maxRateDescription;
                            }

                            survey_map[`${element.name}-${dyn_element.name}`] =
                                dyn_element.title || dyn_element.html;
                        });
                    }

                    let strip_text_element = element.title || element.html;
                    if (strip_text_element && typeof strip_text_element == 'object') {
                        Object.keys(strip_text_element).map((lang) => {
                            langs.push(lang);
                        });
                    } else {
                        langs.push('default');
                    }
                });
            });
        });
        if (survey.title) {
            survey_map['pages-title'] = survey.title;
        }
        survey_map['langs'] = [...new Set(langs)];
        return survey_map;
    }
    getSurveySheetOptions(useSelection = true) {
        let worksheets = [];
        let surveys = this.getSurveyDatasource(useSelection);
        surveys.map((survey) => {
            let survey_data = this.tranformSurvey(survey);
            let headers = [
                {
                    name: 'key',
                    key: 'key',
                },
            ];
            survey_data['langs'].map((lang) => {
                headers.push({
                    name: lang,
                    key: lang,
                });
            });
            const sheetoptions = {
                tableTitle: survey.id,
                headerGroups: [],
                headerDefinition: headers,
            };

            let sheetname =
                survey.id.substring(0, 5) +
                ' ' +
                this.removeExcelWorksheetNameIllegalCharacters(this.getText(survey.name));

            const options = {
                sheetName: sheetname,
                startingRowNumber: 1,
                gapBetweenTwoTables: 2,
                tableSettings: {
                    [survey.id]: sheetoptions,
                },
            };
            worksheets.push(options);
        });
        return worksheets;
    }
    buildSurveyData(useSelection = true) {
        let data = [];
        this.getSurveyDatasource(useSelection).map((survey) => {
            let survey_data = this.tranformSurvey(survey);
            let trans_arr = [];
            Object.keys(survey_data).map((k) => {
                if (k == 'langs') return;

                let trans_data = {};
                trans_data['key'] = k;
                survey_data['langs'].map((lang) => {
                    if (survey_data[k] && survey_data[k].hasOwnProperty(lang)) {
                        trans_data[lang] = survey_data[k][lang];
                    } else if (typeof survey_data[k] == 'string' && lang == 'default') {
                        trans_data[lang] = survey_data[k];
                    } else {
                        trans_data[lang] = '';
                    }
                });
                trans_arr.push(trans_data);
            });

            data.push({ [survey.id]: trans_arr });
        });

        return data;
    }
    buildSurveyExport() {
        this.loadingExport = true;
        const file = {
            fileName: `custom_${this.selectedRegistry.registry_id}_Survey_translations`,
            workSheets: this.getSurveySheetOptions(),
        };
        let data = this.buildSurveyData();
        excelExport.downloadExcel(file, data);
        this.loadingExport = false;
    }

    getRegistryConfigurationDatasource(useSelection) {
        if (!useSelection && this.selectedRegistry.registry_id == 'All') {
            return this.registries;
        } else {
            return [this.selectedRegistry];
        }
    }
    transformRegistryConfiguration(raw_data) {
        let rc_map = [];
        raw_data.map((rc_item) => {
            let cp = Object.assign({}, rc_item);
            delete cp.languages;

            if (cp.hasOwnProperty('library')) {
                cp.library = this.tryparsejson(cp.library);
            }

            if (cp.hasOwnProperty('registrationDetails')) {
                cp.registrationDetails = this.tryparsejson(cp.registrationDetails);
            }
            let raw_flat = flattenObject(cp);

            let trimmed = {};
            Object.keys(raw_flat)
                .filter((k) => {
                    let spl = k.split('.');
                    if (rc_item.hasOwnProperty('languages'))
                        return this.tryparsejson(rc_item.languages).includes(spl[spl.length - 1]);
                    else {
                        return ['en'].includes(spl[spl.length - 1]);
                    }
                })
                .map((k) => {
                    trimmed[`${cp.registry_id}.${k}`] = raw_flat[k];
                });
            rc_map.push(this.transformFlatByLangKeys(trimmed));
        });

        return rc_map;
    }
    getRegistryConfigurationSheetOptions(useSelection = true) {
        let headers = [
            {
                name: 'key',
                key: 'key',
            },
        ];

        let raw_data = this.getRegistryConfigurationDatasource(useSelection);
        let langs = [];
        raw_data.map((rc_item) => {
            if (rc_item.hasOwnProperty('languages'))
                langs.push(...this.tryparsejson(rc_item.languages));
            else langs.push('default');
        });
        langs = [...new Set(langs)];
        langs.map((lang) => {
            headers.push({ name: lang, key: lang });
        });

        let sheetoptions = {
            tableTitle: this.selectedRegistry.registry_id,
            headerGroups: [],
            headerDefinition: headers,
        };
        return {
            sheetName: 'Registry Config',
            startingRowNumber: 1,
            gapBetweenTwoTables: 2,
            tableSettings: {
                reg_config: sheetoptions,
            },
        };
    }
    buildRegistryConfigurationData(useSelection = true) {
        let data = [];

        let raw_data = this.getRegistryConfigurationDatasource(useSelection);
        let langs = [];
        raw_data.map((rc_item) => {
            if (rc_item.hasOwnProperty('languages'))
                langs.push(...this.tryparsejson(rc_item.languages));
            else langs.push('default');
        });
        langs = [...new Set(langs)];
        this.transformRegistryConfiguration(raw_data).map((rc_data) => {
            Object.keys(rc_data).map((k) => {
                if (useSelection && !this.registryConfigurationSelection[k.split('.')[1]]) return;
                let item = { key: k };
                langs.map((lang) => {
                    if (rc_data[k].hasOwnProperty(lang)) {
                        item[lang] = rc_data[k][lang];
                    } else if (rc_data[k].hasOwnProperty('en')) {
                        item[lang] = rc_data[k]['en'];
                    }
                });
                data.push(item);
            });
        });

        data = [...new Set(data)];

        return [{ reg_config: data }];
    }
    buildRegistryConfigurationExport() {
        this.loadingExport = true;
        const file = {
            fileName: `custom_${this.selectedRegistry.registry_id}_RegistryConfiguration_translations`,
            workSheets: [this.getRegistryConfigurationSheetOptions()],
        };

        let data = this.buildRegistryConfigurationData();
        excelExport.downloadExcel(file, data);
        this.loadingExport = false;
    }

    getPlatformSelection(useSelection) {
        debugger;
        if (useSelection)
            return Object.keys(this.platformStrings).filter(
                (t) => this.platformStringsSelection[t]
            );
        return this.listPlatformStrings();
    }
    getPlatformStringsSheetOptions(useSelection = true) {
        let headers = [
            {
                name: 'key',
                key: 'key',
            },
        ];
        let langs = this.getPlatformSelection(useSelection);
        langs.map((lang) => {
            headers.push({
                name: lang,
                key: lang,
            });
        });
        const sheetoptions = {
            tableTitle: 'PlatformStrings',
            headerGroups: [],
            headerDefinition: headers,
        };
        return {
            sheetName: 'PlatformStrings',
            startingRowNumber: 1,
            gapBetweenTwoTables: 2,
            tableSettings: {
                platform_strings: sheetoptions,
            },
        };
    }
    buildPlatformStringsData() {
        let data = [];

        let langs = [...new Set(Object.keys(this.platformStrings))];
        let flat = flattenObject(this.platformStrings);
        Object.keys(this.platformStrings['en']).map((k) => {
            Object.keys(this.platformStrings['en'][k]).map((k2) => {
                let data_item = {};
                langs.map((lang) => {
                    let prop = `${lang}.${k}.${k2}`;
                    if (flat.hasOwnProperty(prop)) {
                        data_item['key'] = `${k}.${k2}`;
                        data_item[lang] = flat[prop];
                    }
                });
                data.push(data_item);
            });
        });

        return [{ platform_strings: data }];
    }
    buildPlatformStringsExport(useSelection = true) {
        this.loadingExport = true;
        const file = {
            fileName: `PlatformString_translations`,
            workSheets: [this.getPlatformStringsSheetOptions(useSelection)],
        };

        let data = this.buildPlatformStringsData();
        excelExport.downloadExcel(file, data);
        this.loadingExport = false;
    }

    async buildAllExport() {
        this.loadingExport = true;
        let data = [];
        let worksheets = [];
        let useSelection = false;

        worksheets.push(this.getRegistryConfigurationSheetOptions(useSelection));
        data.push(...this.buildRegistryConfigurationData(useSelection));

        worksheets.push(this.getEmailTemplateSheetOptions());
        data.push({
            email_templates: await this.getSelectedEmailTemplateData(useSelection),
        });

        worksheets.push(this.getTaskPrototypeSheetOptions(useSelection));
        data.push(...this.buildTaskPrototypeData(useSelection));

        worksheets.push(...this.getSurveySheetOptions(useSelection));
        data.push(...this.buildSurveyData(useSelection));

        if (this.selectedRegistry.registry_id == 'All') {
            worksheets.push(this.getPlatformStringsSheetOptions());
            data.push(...this.buildPlatformStringsData());
        }

        Promise.all(data).then(() => {
            const file = {
                fileName: `all_${this.selectedRegistry.registry_id}_translations`,
                workSheets: worksheets,
            };
            excelExport.downloadExcel(file, data);
            this.loadingExport = false;
        });
    }
    async buildAllSelectedExport() {
        this.loadingExport = true;
        let data = [];
        let worksheets = [];
        let keys = Object.keys(this.sectionItems);
        for (let i in keys) {
            let k = keys[i];
            if (this.sectionItems[k]) {
                switch (k) {
                    case 'Email-Templates':
                        worksheets.push(this.getEmailTemplateSheetOptions());
                        data.push({
                            email_templates: await this.getSelectedEmailTemplateData(),
                        });
                        break;
                    case 'Registry-Configuration-Data':
                        worksheets.push(this.getRegistryConfigurationSheetOptions(false));

                        data.push(...this.buildRegistryConfigurationData(false));
                        break;
                    case 'Tasks':
                        worksheets.push(this.getTaskPrototypeSheetOptions());
                        data.push(...this.buildTaskPrototypeData());
                        break;
                    case 'Surveys':
                        worksheets.push(...this.getSurveySheetOptions());
                        data.push(...this.buildSurveyData());
                        break;
                }
            }
        }

        Promise.all(data).then(() => {
            const file = {
                fileName: `${this.selectedRegistry.registry_id}_custom_translations`,
                workSheets: worksheets,
            };
            excelExport.downloadExcel(file, data);
            this.loadingExport = false;
        });
    }

    //#endregion
}

function flattenObject(ob) {
    var toReturn = {};

    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if (typeof ob[i] == 'object' && ob[i] !== null) {
            var flatObject = flattenObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}
