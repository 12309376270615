import { Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFormChoice } from '@h20-services/models/forms/IFormChoice';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { IFormRating } from '@h20-services/models/forms/types/IFormRating';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';

@Component({
    selector: 'app-pi-control-rating',
    templateUrl: './pi-control-rating.component.html',
    styleUrls: ['./pi-control-rating.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlRatingComponent,
        },
    ],
})
export class PiControlRatingComponent
    extends AbstractPiControlComponent<IFormRating, number>
    implements OnInit
{
    minRate: number = 0;
    maxRate: number = 5;
    stepRate: number = 1;
    minRateDescription: string;
    maxRateDescription: string;
    rateValue: number;

    controlValues: number[];

    ngOnInit(): void {
        super.ngOnInit();

        //todo: when we can migrate survey config jsons, we might want to update this
        //i.e. fix form-type 'number' to actually return a number;
        //update this to expect numbers;
        //update all surveys to have numbers for these values instead of strings
        const parsedMinRate = parseInt(this.element.rateMin);
        if (!isNaN(parsedMinRate)) {
            this.minRate = parsedMinRate;
        }

        const parsedMaxRate = parseInt(this.element.rateMax);
        if (!isNaN(parsedMaxRate)) {
            this.maxRate = parsedMaxRate;
        }

        const parsedStepRate = parseInt(this.element.rateStep);
        if (!isNaN(parsedStepRate)) {
            this.stepRate = parsedStepRate;
        }

        this.minRateDescription = this.element.minRateDescription || 'lowest';
        this.maxRateDescription = this.element.maxRateDescription || 'highest';

        this.controlValues = [];

        for (let i = this.minRate; i <= this.maxRate; i += this.stepRate) {
            this.controlValues.push(i);
        }

        if (this.formControl.value) {
            this.rateValue = parseInt(this.formControl.value);
        }
    }
    selectionMade(choice: number) {
        this.markAsTouched();
        this.writeValue(choice);
        this.rateValue = choice;
        this.notifyValueChange();
    }
}
