import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@h20-services/config.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { AuthService } from '@h20-services/auth.service';
import { UserRole } from '@h20-services/models/role';

@Component({
    selector: 'app-profile-setting',
    templateUrl: './profile-settings.component.html',
    styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit {
    name: string;
    email: string;
    isPatientOrProxy: boolean;
    showAddressFields: boolean = false;
    firstName: string;
    lastName: string;
    gender;
    city;
    country;
    state;
    notEntered = this.translate.instant('Registry.NoAnswer');
    pulseAuth: PulseAuth = PulseAuth.NotLoggedIn;
    type;
    selectedDateFormat;
    userDetails;
    loading = true;

    constructor(public translate: TranslateService, private readonly auth: AuthService) {}

    ngOnInit(): void {
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            this.loading = false;
            this.pulseAuth = pulseAuth;

            this.name = this.pulseAuth.getPreferredName();
            this.email = this.pulseAuth.getEmailAddr();
            this.type = this.pulseAuth.getType();
            this.selectedDateFormat = this.pulseAuth.getPreferredDateFormat();

            this.userDetails = this.pulseAuth.getUserDetails();
            this.gender = this.userDetails.gender || '';
            this.getName(this.userDetails);
            if (this.type === UserRole.Patient || this.type === UserRole.Proxy) {
                this.isPatientOrProxy = true;
            }
        });
    }

    getName(userDetails) {
        if (userDetails.firstName && userDetails.lastName) {
            this.firstName = userDetails.firstName;
            this.lastName = userDetails.lastName;
        } else {
            const nameParts = this.name ? this.name.split(' ') : [];

            if (nameParts.length === 0) {
                this.firstName = '';
                this.lastName = '';
            } else if (nameParts.length === 1) {
                this.firstName = nameParts[0];
                this.lastName = '';
            } else if (nameParts.length === 2) {
                this.firstName = nameParts[0];
                this.lastName = nameParts[1];
            } else {
                this.firstName = nameParts[0];
                this.lastName = nameParts[nameParts.length - 1];
            }
        }
    }
}
