import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { DateCompareValidator, IFormElement } from '@h20-services/models/forms/IFormElement';
import { TranslateService } from '@ngx-translate/core';
import { PartialDate } from '../models/PartialDate';
import { UuidService } from '@h20-services/uuid.service';

import { environment as env } from '@environment/environment';
@Component({
    template: '',
})
export class AbstractPiControlComponent<ElementType extends IFormElement, ValueType = any>
    implements ControlValueAccessor, OnInit
{
    @Input() element: ElementType;
    @Input() formControl: UntypedFormControl;
    @Input() formControlList: AbstractControl[];
    @Input() elementList: ElementType[];
    @Input() hideDisabled: boolean = true;
    @Input() mode: string = 'form';
    @Input() parentList: any[]; // form builder - logic builder
    @Input() options;

    _logicalContext: any;
    @Input() set logicalContext(logicalContext: any) {
        this._logicalContext = logicalContext;
    }

    disabled: boolean;
    touched = false;
    elementUID: string;

    @Output()
    controlBlur = new EventEmitter<void>();

    @Output()
    controlFocus = new EventEmitter<void>();

    //Track a second form control and sync the value to this control
    // validators on the warning control allow us to track these
    // warnings and use any existing validator as a warning instead
    // without impacting the validity of the form
    warningControl: UntypedFormControl;
    customErrorMessages: any = {};
    customWarningMessages: any = {};

    protected _value: ValueType;
    @Input()
    set value(value: ValueType) {
        this._value = value;
        this.setStringValue();
        this.notifyValueChange();
        //sync the warning control value to update warnings
        this.warningControl.setValue(value);
    }

    get value(): ValueType {
        return this._value;
    }

    onChange: (value: ValueType) => {};
    onTouched() {}

    protected stringValue: string = '';
    setStringValue() {
        this.stringValue = String(this._value);
    }

    protected cdRef: ChangeDetectorRef;

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService
    ) {
        this.cdRef = injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
    }

    ngOnInit(): void {
        this.addValidators();
        this.setImageUrl();
        this.EUID();
        this.warningControl = new UntypedFormControl();
        this.addWarnings();
    }

    EUID(): string {
        if (!this.elementUID) {
            this.elementUID = this.element.name + '-' + this.uuid_svc.uuid();
        }
        return this.elementUID;
    }

    notifyValueChange(): void {
        if (this.onChange) {
            this.onChange(this._value);
        }
    }

    writeValue(value: ValueType): void {
        this._value = value;
        this.setStringValue();
        //sync the warning control value to update warnings
        this.warningControl?.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    getText = this.com_svc.getText;

    check() {
        console.error(this._value);
    }

    // Detect regex's implemented in configuration that
    // might be testing for email patterns.  We fear that
    // many might prevent upper-case chars.
    private looksLikeEmailRegex(src: string): boolean {
        if (src.includes('@')) return true;
        return false;
    }

    // implement our on version Angular.

    private makePulsePatternValidator(pattern, forceCaseInsensitive: boolean) {
        if (!pattern) return Validators.nullValidator;

        let caseInsensitive = forceCaseInsensitive;
        let regex;
        let regexStr;
        if (typeof pattern === 'string') {
            // See note above
            caseInsensitive = this.looksLikeEmailRegex(pattern);
            regexStr = '';
            if (pattern.charAt(0) !== '^') regexStr += '^';
            regexStr += pattern;
            if (pattern.charAt(pattern.length - 1) !== '$') regexStr += '$';
            regex = new RegExp(regexStr);
        } else {
            regexStr = pattern.toString();
            regex = pattern;
        }

        return (control) => {
            // this is the actual validation code
            if (!control.value || control.value.length === 0) {
                return null; // don't validate empty values to allow optional controls
            }
            let value = control.value;
            if (value && caseInsensitive) value = value.toLowerCase();

            return regex.test(value)
                ? null
                : { pattern: { requiredPattern: regexStr, actualValue: value } };
        };
    }

    private makeRegexValidator(val): ValidatorFn {
        const innerFn: ValidatorFn = Validators.pattern(val.regex);
        const regexStr = 'HA!';
        const outerFn: ValidatorFn = (formGroup: UntypedFormGroup) => {
            // wrap Angular innerFn for debugging, mucking
            return innerFn(formGroup);
        };
        return outerFn;
    }

    addValidators() {
        if (this.element.isRequired) {
            this.formControl.addValidators(Validators.required);
            if (this.element.requiredErrorText !== undefined) {
                this.customErrorMessages['required'] = this.element.requiredErrorText;
            }
        }
        if (this.element.min) {
            this.formControl.addValidators(Validators.min(this.element.min));
            if (this.element.minErrorText !== undefined) {
                this.customErrorMessages['min'] = this.element.minErrorText;
            }
        }
        if (this.element.max) {
            this.formControl.addValidators(Validators.max(this.element.max));
            if (this.element.maxErrorText !== undefined) {
                this.customErrorMessages['max'] = this.element.maxErrorText;
            }
        }

        if (this.element.validators) {
            for (const val of this.element.validators) {
                if (val.type === 'regex') {
                    const fn = this.makePulsePatternValidator(val.regex, false);
                    this.formControl.addValidators(fn);
                }
                if (val.text !== undefined) {
                    this.customErrorMessages['pattern'] = val.text;
                }
            }
        }
    }

    convertAdvValidatorToDateCompare(json: string): Array<DateCompareValidator> {
        const dateCompareValidators = [];
        const advValidators = JSON.parse(this.element.advancedValidators);
        if (advValidators?.compareDate) {
            for (const [operation, targetElementName] of Object.entries(
                advValidators.compareDate
            )) {
                dateCompareValidators.push({
                    operation: operation,
                    compareValueName: targetElementName as string,
                    errorText: advValidators.compareDateErrorText,
                });
            }
        }
        return dateCompareValidators;
    }

    compareDateValidator(): any {
        let dcVals: Array<DateCompareValidator>;
        if (this.element.dateCompareValidators) {
            dcVals = this.element.dateCompareValidators;
        } else if (this.element.advancedValidators) {
            dcVals = this.convertAdvValidatorToDateCompare(this.element.advancedValidators);
        } else {
            return {};
        }
        const thisElementDate = this.convertToDateValue(this.element.type, this.formControl.value);
        if (!thisElementDate || thisElementDate.length <= 0) {
            return {};
        }

        const results = dcVals.flatMap((v) => {
            const result = this.compareDateValidatorCheck(v, thisElementDate);
            return result === undefined ? [] : [result];
        });

        if (results.length === 0) {
            return {};
        }

        const errors = { compareDate: [] };
        for (const result of results) {
            const err = {
                targetQuestion: result.targetQuestion,
                condition: result.condition,
                errorText: result.errorText,
            };
            if (result.errorText === undefined || result.errorText.length <= 0) {
                delete err.errorText;
            }
            errors.compareDate.push(err);
        }

        return errors;
    }

    compareDateValidatorCheck(
        dcVal: DateCompareValidator,
        currentDate: string
    ): undefined | { targetQuestion: string; condition: string; errorText: undefined | string } {
        if (!Object.prototype.hasOwnProperty.call(this.formControlList, dcVal.compareValueName)) {
            return;
        }

        const control = this.formControlList[dcVal.compareValueName];

        if (!control.value || !control.status || control.status === 'DISABLED') {
            return;
        }

        const element = this.elementList.find((v) => {
            return v.name === dcVal.compareValueName;
        });

        if (!element) {
            return;
        }

        const targetDate = this.convertToDateValue(element.type, control.value);

        if (
            targetDate?.length > 0 &&
            !this.isCompareDateValid(dcVal.operation, currentDate, targetDate)
        ) {
            return {
                targetQuestion: this.getText(element.title),
                condition: this.com_svc.translateCompareOpText(dcVal.operation),
                errorText: dcVal.errorText,
            };
        }
    }

    // Return 'YYYY-MM-DD'. To uniform format to compare dates
    convertToDateValue(type, value): any {
        let convertedValue = value;
        if (value) {
            if (type === 'partial-date') {
                let date = new PartialDate();
                date.setDateFromAny(value);
                convertedValue = date.toStringWithZero();
            } else if (type === 'multipletext') {
                let dateStr = '';
                for (let i in value) {
                    if (value[i] !== '') {
                        dateStr += value[i].toString().length > 1 ? value[i] : '0' + value[i];
                        dateStr += '-';
                    } else {
                        dateStr += '00';
                        dateStr += '-';
                    }
                }
                if (dateStr !== '') dateStr = dateStr.slice(0, -1);
                convertedValue = dateStr;
            } else if (type === 'datepicker') {
                let dateStr = '';
                if (value.split('-').length > 0) {
                    const tokens = value.split('-');
                    for (let tok of tokens) {
                        dateStr += tok.length > 1 ? tok : '0' + tok;
                        dateStr += '-';
                    }
                }
                if (dateStr !== '') dateStr = dateStr.slice(0, -1);
                convertedValue = dateStr;
            }
        }
        return convertedValue;
    }

    // In case one only has year or month, the structure is set to be the same and compare.
    // ToDo: Improve the code to use module etc and move to service later
    isCompareDateValid(operation, firstDate, secondDate): boolean {
        const fTokens = firstDate.split('-');
        const sTokens = secondDate.split('-');
        let newFirst = '';
        let newSecond = '';

        for (let i = 0; i < 3; i++) {
            if (fTokens[i] && sTokens[i]) {
                newFirst += i == 0 ? fTokens[i] : `-${fTokens[i]}`;
                newSecond += i == 0 ? sTokens[i] : `-${sTokens[i]}`;
            }
        }
        return this.com_svc.compareByOpText(operation, newFirst, newSecond);
    }

    setImageUrl() {
        let imageExtensions = ['png', 'jpg', 'jpeg', 'jpe', 'jfif'];
        if (this.element.tooltip) {
            let splitArr = this.getText(this.element.tooltip).split('.');

            if (this.getText(this.element.tooltip).split(':')[0] !== 'https') {
                if (imageExtensions.find((el) => el === splitArr[splitArr.length - 1])) {
                    this.element.tooltip = `${env.awsConfig.Path.defaultWebAssetPath}/${this.element.tooltip}`;
                }
            }
        }
    }

    addWarnings() {
        if (this.element.isRequiredWarn) {
            this.warningControl.addValidators(Validators.required);
            if (this.element.requiredErrorTextWarn !== undefined) {
                this.customWarningMessages['required'] = this.element.requiredErrorTextWarn;
            }
        }
        if (this.element.minWarn) {
            this.warningControl.addValidators(Validators.min(this.element.minWarn));
            if (this.element.minWarnText !== undefined) {
                this.customWarningMessages['min'] = this.element.minWarnText;
            }
        }
        if (this.element.maxWarn) {
            this.warningControl.addValidators(Validators.max(this.element.maxWarn));
            if (this.element.maxWarnText !== undefined) {
                this.customWarningMessages['max'] = this.element.maxWarnText;
            }
        }
    }
}
