import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { ELEMENT_TYPES } from '@h20-services/enums/elementTypes/ElementType';
@Component({
    selector: 'app-survey-rtv',
    templateUrl: './survey-rtv.component.html',
    styleUrls: ['./survey-rtv.component.scss'],
})
export class SurveyRtvComponent implements OnInit, OnChanges {
    constructor(
        private http: HttpClient,
        private common: CommonService
    ) {}
    currentEditor: number = 0;
    @Input() survey: any;
    surveyPanels: any;
    formForm: any;
    currentElement: any;
    update = 0;
    elementIndex: number = 0;
    elementInd: number = 0;
    panelIndex: number = 0;
    randomNumber: number = 0;
    currentSurvey;
    currentSurveyData;
    currentLayout = 'flat';
    jsonEditorOptions: JsonEditorOptions;
    adding: boolean = false;
    navExpanded: boolean = true;
    addingBefore: boolean = false;
    addingAt: number = 0;
    addingForm: any;
    ELEMENT_TYPES;
    ngOnInit(): void {
        this.http.get('assets/survey-builder/element-forms/form.json').subscribe((data) => {
            this.formForm = data;
        });
        this.jsonEditorOptions = new JsonEditorOptions();
        this.jsonEditorOptions.modes = ['view'];
        this.jsonEditorOptions.mode = 'view';
        if (this.survey && this.survey.pages) this.surveyPanels = this.survey.pages[0].elements;
        this.currentElement = this.survey.pages[0].elements[this.panelIndex].elements[0];
        this.ELEMENT_TYPES = ELEMENT_TYPES;
    }

    ngOnChanges(): void {
        if (this.survey && this.survey.pages) {
            this.currentSurvey = this.survey;
            this.surveyPanels = this.survey.pages[0].elements;
        }
    }

    onFormChanges(event: any) {
        this.survey = Object.assign(this.survey, event.value);
    }

    onOtherFormChanges(formGroup) {
        this.currentSurveyData = formGroup.value;
    }

    getText = this.common.getText;

    selectQuestion() {
        this.update++;
    }

    toggleLayout() {
        if (this.currentLayout == 'flat') {
            this.currentLayout = 'one-by-one';
        } else if (this.currentLayout == 'one-by-one') {
            this.currentLayout = 'flat';
        }
    }
    check() {
        console.error(this.currentSurvey, this.survey);
    }

    elementChange(event) {
        if (event.newIndex !== undefined && event.panelInd !== undefined) {
            this.elementIndex = event.newIndex;
            this.panelIndex = event.panelInd;
            this.currentElement =
                this.survey.pages[0].elements[this.panelIndex].elements[this.elementIndex];
        } else if (event.elIndex !== undefined && event.panelInd !== undefined) {
            this.randomNumber = event.panelInd;
        } else {
        }
    }

    addPanel() {
        let newPanel = {
            type: 'panel',
            elements: [],
        };
        this.survey.pages[0].elements.push(newPanel);
    }

    getColor(element) {
        let type = element.inputType ? element.inputType : element.type;
        return ELEMENT_TYPES.find((el) => {
            return el.text === type;
        })?.color;
    }

    getIcon(element) {
        let type = element.inputType ? element.inputType : element.type;
        return ELEMENT_TYPES.find((el) => {
            return el.text === type;
        })?.icon;
    }

    addAfter(index, panelIndex) {
        this.panelIndex = panelIndex;
        this.adding = true;
        this.addingAt = index;
        this.addingBefore = false;
    }

    changeEditor(newIndex: number, panelIndex) {
        this.currentEditor = newIndex;
        this.panelIndex = panelIndex;
        this.elementIndex = newIndex;
        this.currentElement =
            this.survey.pages[0].elements[this.panelIndex].elements[this.currentEditor];
    }

    expandedControl(panelInd, panel) {
        this.panelIndex = panelInd;
    }

    clearAdding() {
        this.adding = false;
        this.addingAt = 0;
        this.addingBefore = false;
        return true;
    }

    confirmAdd(type: string) {
        let newElement = { type: type };
        if (
            type === 'number' ||
            type === 'date' ||
            type === 'email' ||
            type === 'slider' ||
            type === 'time'
        ) {
            newElement.type = 'text';
            newElement['inputType'] = type;
        } else if (type === 'paneldynamic') {
            newElement['templateElements'] = [];
        } else if (type === 'checkbox') {
            newElement['choices'] = [];
        }
        newElement['name'] = '';
        newElement['title'] = '';
        let addIndex = this.addingBefore ? this.addingAt : this.addingAt;
        this.elementInd = addIndex;
        this.survey.pages[0].elements[this.panelIndex].elements.splice(addIndex, 0, newElement);
        this.changeEditor(this.addingAt, this.panelIndex);
        this.clearAdding();
    }

    deleteSection(section: number): void {
        this.survey.pages[0].elements.splice(section, 1);
    }

    moveUp(index) {
        if (index === 0) return;
        let temp = this.survey.pages[0].elements[this.panelIndex].elements[index - 1];
        this.survey.pages[0].elements[this.panelIndex].elements[index - 1] =
            this.survey.pages[0].elements[this.panelIndex].elements[index];
        this.survey.pages[0].elements[this.panelIndex].elements[index] = temp;
        this.changeEditor(index, this.panelIndex);
    }

    moveDown(index) {
        if (index + 1 == this.survey.pages[0].elements[this.panelIndex].elements.length) return;
        let temp = this.survey.pages[0].elements[this.panelIndex].elements[index + 1];
        this.survey.pages[0].elements[this.panelIndex].elements[index + 1] =
            this.survey.pages[0].elements[this.panelIndex].elements[index];
        this.survey.pages[0].elements[this.panelIndex].elements[index] = temp;
        this.changeEditor(index, this.panelIndex);
    }

    addBefore(index, panelIndex) {
        this.panelIndex = panelIndex;
        this.adding = true;
        this.addingAt = index;
        this.addingBefore = true;
    }

    editPanelName(i) {
        document.getElementById('#panel' + i).focus();
        document.getElementById('#panel' + i).style.removeProperty('pointer-events');
        document.getElementById('#panelAccordion-collapse' + i).removeAttribute('data-bs-toggle');
    }

    panelSecFocusOutEvent(e, i) {
        this.surveyPanels[i].title = e.target.value;
        document.getElementById('#panel' + i).style.setProperty('pointer-events', 'none');
        document
            .getElementById('#panelAccordion-collapse' + i)
            .setAttribute('data-bs-toggle', 'collapse');
    }
}
