import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR,
    ValidatorFn,
    AbstractControl,
} from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { FormService } from '@h20-services/form.service';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { IFormMultitext } from '@h20-services/models/forms/types/IFormMultitext';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { group } from '@angular/animations';
import { UuidService } from '@h20-services/uuid.service';

@Component({
    selector: 'app-pi-control-multitext',
    templateUrl: './pi-control-multitext.component.html',
    styleUrls: ['./pi-control-multitext.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlMultitextComponent,
        },
    ],
})
export class PiControlMultitextComponent
    extends AbstractPiControlComponent<IFormMultitext, any>
    implements OnInit, OnDestroy
{
    controlFormGroup: UntypedFormGroup;
    onChangeSubs: Subscription[] = [];

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        protected fb: UntypedFormBuilder,
        protected form_svc: FormService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    ngOnInit(): void {
        const group: any = {};
        this.element.items?.forEach((item: IFormElement) => {
            group[item.name] = new UntypedFormControl();
        });

        this.controlFormGroup = this.fb.group(group);
        super.ngOnInit();

        this.formControl.addValidators(this.validators());
    }

    ngOnDestroy() {
        for (let sub of this.onChangeSubs) {
            sub.unsubscribe();
        }
    }

    registerOnChange(onChange: any) {
        const sub = this.controlFormGroup.valueChanges.subscribe(onChange);
        this.onChangeSubs.push(sub);
    }

    setStringValue() {
        this.stringValue = ''; //reset

        if (this._value) {
            for (const [k, val] of Object.entries(this._value)) {
                if (val) {
                    const textValue = this.getText(String(val));
                    this.stringValue =
                        this.mode === 'view'
                            ? this.stringValue.concat(textValue).concat(' ')
                            : this.stringValue.concat(textValue);
                }
            }
            // remove last char
            if (this.stringValue !== '' && this.mode === 'view')
                this.stringValue = this.stringValue.slice(0, -1);
        }
    }

    writeValue(value: any): void {
        this.controlFormGroup.patchValue(value);
        this._value = this.controlFormGroup.value;
        this.setStringValue();
    }

    onChoiceChange(choice: any): void {
        this.markAsTouched();
        this._value = this.controlFormGroup.value;
        this.notifyValueChange();
    }

    validators() {
        const requiredFieldsValidator: ValidatorFn = (control: AbstractControl) => {
            const errors: any = {};

            //all required pieces must have values
            if (this.element.isRequired) {
                this.element.items.forEach((item: IFormElement) => {
                    if (
                        item.isRequired &&
                        control.value &&
                        (!control.value.hasOwnProperty(item.name) ||
                            control.value[item.name] == null ||
                            control.value[item.name] == '')
                    ) {
                        errors.required = {};
                    }
                });
            }
            return Object.keys(errors).length > 0 ? errors : null;
        };
        const compareDateValidatorFn: ValidatorFn = () => {
            const errors: any = this.compareDateValidator();
            return Object.keys(errors).length > 0 ? errors : null;
        };
        return [requiredFieldsValidator, compareDateValidatorFn];
    }
}
