<div [ngSwitch]="componentType" class="custom-renderer">
    <div *ngSwitchCase="'icon'">
        <i
            [aria-label]="additionalParams['aria-label']"
            [title]="additionalParams?.title"
            [class]="additionalParams?.iconClass"
            [style]="additionalParams?.colorStyle"
        ></i>
    </div>
    <div *ngSwitchCase="'actionBtn'">
        <app-button
            [iconConfig]="{ iconClasses: 'fa-solid fa-arrow-circle-right ps-1' }"
            (action)="additionalParams.onClick($event)"
            *ngIf="additionalParams?.isVisible"
            [buttonText]="additionalParams?.buttonText"
        ></app-button>
    </div>
    <div *ngSwitchCase="'openAndActionBtns'" style="display: flex">
        <app-button
            class="ms-1 btn btn-sm"
            *ngIf="additionalParams.viewIcon"
            [iconConfig]="{ iconClasses: 'fa-regular fa-folder-open' }"
            (action)="additionalParams.onClick($event)"
        ></app-button>

        <app-button
            class="ms-1 btn btn-sm dropdown"
            [hasDropdown]="true"
            *ngIf="anyActionVisible(additionalParams.actions)"
            [iconConfig]="{ iconClasses: additionalParams?.iconClass ?? 'fa-regular fa-ellipsis' }"
            aria-haspopup="true"
            aria-expanded="false"
            data-bs-toggle="dropdown"
        >
            <div *ngFor="let action of actions">
                <div *ngIf="action.isVisible" class="dropdown-item" (click)="action.onClick()">
                    {{ action.label }}
                </div>
            </div>
        </app-button>
    </div>
    <div
        *ngSwitchCase="'textWithIcon'"
        (click)="additionalParams?.onClick(additionalParams?.iconText)"
    >
        <ng-container *ngIf="additionalParams?.iconPosition === 'before'">
            <app-button
                class="ms-1 d-none d-md-inline"
                [attr.aria-label]="additionalParams?.['aria-label']"
                [iconConfig]="{ iconClasses: additionalParams?.iconClass, true }"
                design="tertiary"
            />
        </ng-container>
        {{ additionalParams?.iconText }}
        <ng-container *ngIf="additionalParams?.iconPosition === 'after'">
            <app-button
                class="ms-1 d-none d-md-inline"
                [attr.aria-label]="additionalParams?.['aria-label']"
                [iconConfig]="{ iconClasses: additionalParams?.iconClass, false }"
                design="tertiary"
            />
        </ng-container>
    </div>
    <div *ngSwitchCase="'dropdown'">
        <select (change)="additionalParams?.onChange($event)" style="width: 110px; height: 25px">
            <ng-container *ngFor="let option of additionalParams?.dropdownOptions">
                <option [selected]="option === additionalParams?.value" [value]="option">
                    {{ option }}
                </option>
            </ng-container>
        </select>
    </div>

    <div *ngSwitchCase="'iconBtns'">
        <div *ngIf="actions?.length" class="d-flex min-height">
            <div *ngIf="additionalParams?.text">{{ additionalParams?.text }}</div>
            <div *ngFor="let action of actions">
                <app-button
                    [buttonClasses]="action.btnClass ?? 'btn-rnd mx-1'"
                    *ngIf="action.isVisible"
                    [title]="action.title"
                    (action)="action.onClick($event)"
                    [iconConfig]="{ iconClasses: action.iconClass }"
                ></app-button>
            </div>
        </div>
    </div>
</div>
