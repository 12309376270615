<app-loading-component [loading]="loading">
    <div class="row mb-3 wrapper">
        <div class="row wrapper">
            <div class="left-column">
                <h4 class="desc-heading">
                    {{ 'UserSettings.PersonalInformation' | translate }}
                </h4>
            </div>
            <div class="details-right-column name-fields">
                <div class="form-group">
                    <div class="multiDiv">
                        <div class="inputDiv multi-input">
                            <h5 class="input-label">
                                {{ 'Registry.FirstName' | translate }}
                            </h5>
                            <input class="form-control input" disabled [value]="firstName ? firstName : notEntered" />
                        </div>
                        <div class="inputDiv multi-input">
                            <h5 class="input-label">
                                {{ 'Registry.LastName' | translate }}
                            </h5>
                            <input class="form-control input" [value]="lastName ? lastName : notEntered" disabled />
                        </div>
                    </div>
                    <div *ngIf="isPatientOrProxy" class="inputDiv">
                        <div class="inputDiv">
                            <h5 class="input-label">
                                {{ 'UserSettings.DateOfBirth' | translate }}
                            </h5>
                            <input class="form-control input" disabled [value]="
                                userDetails['dob']
                                    ? (userDetails['dob'] | date: selectedDateFormat)
                                    : notEntered
                            " />
                        </div>
                        <div class="inputDiv">
                            <div class="inputDiv">
                                <h5 class="input-label">
                                    {{ 'UserSettings.Gender' | translate }}
                                </h5>
                                <select class="form-control input dropdown-gender" disabled [(ngModel)]="gender">
                                    <option value="">
                                        {{ 'Registry.NoAnswer' | translate }}
                                    </option>
                                    <option value="Male">
                                        {{ 'UserSettings.Male' | translate }}
                                    </option>
                                    <option value="Female">
                                        {{ 'UserSettings.Female' | translate }}
                                    </option>
                                    <option value="Other">
                                        {{ 'UserSettings.Other' | translate }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row wrapper">
            <hr />
            <div class="left-column">
                <h4 class="desc-heading">
                    {{ 'UserSettings.ContactDetails' | translate }}
                </h4>
            </div>
            <div class="details-right-column name-fields">
                <div class="form-group">
                    <div class="inputDiv">
                        <h5 class="input-label">
                            {{ 'Login.EmailAddress' | translate }}
                        </h5>
                        <input class="form-control input" disabled [value]="email ? email : notEntered" />
                    </div>
                    <div *ngIf="isPatientOrProxy" class="inputDiv">
                        <div class="inputDiv">
                            <h5 class="input-label">
                                {{ 'UserSettings.PhoneNumber' | translate }}
                            </h5>
                            <input class="form-control input" disabled
                                [value]="userDetails['mobile'] ? userDetails['mobile'] : notEntered" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row wrapper">
            <div *ngIf="isPatientOrProxy && showAddressFields" class="row mb-3 wrapper">
                <hr />
                <div class="left-column">
                    <h4 class="desc-heading">
                        {{ 'UserSettings.Address' | translate }}
                    </h4>
                </div>
                <div class="details-right-column name-fields">
                    <div class="form-group">
                        <div class="inputDiv">
                            <h5 class="input-label">
                                {{ 'UserSettings.StreetAddress' | translate }}
                            </h5>
                            <input class="form-control input" disabled [value]="
                                userDetails['streetAddress']
                                    ? userDetails['streetAddress']
                                    : notEntered
                            " />
                        </div>
                        <div *ngIf="isPatientOrProxy" class="inputDiv">
                            <div class="inputDiv">
                                <h5 class="input-label">
                                    {{ 'UserSettings.City' | translate }}
                                </h5>
                                <input class="form-control input" [value]="city ? city : notEntered" disabled />
                            </div>
                        </div>
                        <div class="multiDiv">
                            <div class="multiDiv">
                                <div class="inputDiv multi-input">
                                    <h5 class="input-label">
                                        {{ 'UserSettings.State' | translate }}
                                    </h5>
                                    <input class="form-control input" disabled [value]="state ? state : notEntered" />
                                </div>
                                <div class="inputDiv multi-input">
                                    <h5 class="input-label">
                                        {{ 'UserSettings.Pincode' | translate }}
                                    </h5>
                                    <input class="form-control input" disabled [value]="
                                        userDetails['postalCode']
                                            ? userDetails['postalCode']
                                            : notEntered
                                    " />
                                </div>
                            </div>
                            <div class="inputDiv multi-input">
                                <h5 class="input-label">
                                    {{ 'UserSettings.Country' | translate }}
                                </h5>
                                <input class="form-control input" disabled [value]="country ? country : notEntered" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-loading-component>