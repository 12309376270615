import { Component, Injector, OnInit, Renderer2, ElementRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { IFormGesture } from '@h20-services/models/forms/types/IFormGesture';
import { IGesture } from '@h20-services/models/IGesture';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { UuidService } from '@h20-services/uuid.service';
import { environment as env } from '@environment/environment';

@Component({
    selector: 'app-pi-control-gesture',
    templateUrl: './pi-control-gesture.component.html',
    styleUrls: ['./pi-control-gesture.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlGestureComponent,
        },
    ],
})
export class PiControlGestureComponent
    extends AbstractPiControlComponent<IFormGesture, any>
    implements OnInit
{
    imageUrl: string;
    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        private renderer2: Renderer2,
        private el: ElementRef
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.imageUrl = `${env.awsConfig.Path.defaultWebAssetPath}/${this.element.imageurl}`;
        // TODO: This should get removed after HEAP-1083
        setTimeout(() => {
            if (this.formControl.value?.length > 0) {
                // Render the circles on the screen
                this.element.gestures = this.formControl.value;
                this.renderCircles(this.element.gestures);
            } else {
                this.element.gestures = [];
            }
        }, 0);
    }

    imgClick(event) {
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        // create the circle and put it on the screen
        const circle = this.renderer2.createElement('div');
        this.renderer2.addClass(circle, 'gesture-dot-small');
        this.renderer2.setProperty(circle, 'id', `gesture-dot-${x}-${y}`);
        this.renderer2.setStyle(circle, 'position', 'absolute');
        this.renderer2.setStyle(circle, 'left', `${x}px`);
        this.renderer2.setStyle(circle, 'top', `${y}px`);

        const overlay = this.el.nativeElement.querySelector('#gesture-overlay');
        this.renderer2.appendChild(overlay, circle);

        // Click on the dot already on the screen
        this.renderer2.listen(circle, 'click', (e: Event) => {
            this.clickGestureDot(e.target as HTMLElement);
        });

        // Add new gesture to element
        const newGesture: IGesture = {
            id: circle.id,
            locationX: x,
            locationY: y,
            size: 'small',
        };

        this.markAsTouched();
        this.element.gestures.push(newGesture);
        this._value = this.element.gestures;
        this.notifyValueChange();
    }

    clickGestureDot(element) {
        const elementIndex = this.element.gestures.findIndex(
            (gesture) => gesture.id === element.id
        );
        switch (element.className) {
            case 'gesture-dot-small':
                element.className = 'gesture-dot-medium';
                this.element.gestures[elementIndex].size = 'medium';
                break;
            case 'gesture-dot-medium':
                element.className = 'gesture-dot-large';
                this.element.gestures[elementIndex].size = 'large';
                break;
            case 'gesture-dot-large':
                element.remove();
                this.element.gestures.splice(elementIndex, 1);
                break;
            default:
                break;
        }
        this.markAsTouched();
        this._value = this.element.gestures;
        this.notifyValueChange();
    }

    renderCircles(gestures: IGesture[]) {
        const overlay = this.el.nativeElement.querySelector('#gesture-overlay');

        for (const gesture of gestures) {
            const circle = this.renderer2.createElement('div');
            this.renderer2.addClass(circle, `gesture-dot-${gesture.size}`);
            this.renderer2.setProperty(circle, 'id', gesture.id);
            this.renderer2.setStyle(circle, 'position', 'absolute');
            this.renderer2.setStyle(circle, 'left', `${gesture.locationX}px`);
            this.renderer2.setStyle(circle, 'top', `${gesture.locationY}px`);

            this.renderer2.appendChild(overlay, circle);

            // Click on the dot already on the screen
            this.renderer2.listen(circle, 'click', (event: Event) => {
                this.clickGestureDot(event.target as HTMLElement);
            });
        }
    }
}
