<div
    *ngIf="control?.invalid && (control?.dirty || control?.touched) && showError"
    class="alert alert-danger mb-0 mt-1 py-2 px-3"
    role="alert"
>
    {{ 'Surveys.Error' | translate }}:

    <span *ngIf="control.errors?.['required']">
        <span *ngIf="!customErrorMessages || !customErrorMessages['required']; else customRequired">
            {{ 'Registry.Required' | translate }}
        </span>

        <ng-template #customRequired>
            <span [innerHtml]="getText(customErrorMessages['required'])"> </span>
        </ng-template>
    </span>

    <span *ngIf="control.errors?.['maxlength']">
        <span
            *ngIf="
                !customErrorMessages || !customErrorMessages['maxlength'];
                else customMaximumLength
            "
        >
            {{ 'Surveys.MaximumLength' | translate }}
        </span>

        <ng-template #customMaximumLength>
            <span [innerHtml]="getText(customErrorMessages['maxlength'])"> </span>
        </ng-template>
    </span>

    <span *ngIf="control.errors?.['min']">
        <span *ngIf="!customErrorMessages || !customErrorMessages['min']; else customMinimumError">
            {{ 'Surveys.MinimumError' | translate: { value: control.errors.min.min } }}
        </span>

        <ng-template #customMinimumError>
            <span [innerHtml]="getText(customErrorMessages['min'])"> </span>
        </ng-template>
    </span>

    <span *ngIf="control.errors?.['max']">
        <span *ngIf="!customErrorMessages || !customErrorMessages['max']; else customMaximumError">
            {{ 'Surveys.MaximumError' | translate: { value: control.errors.max.max } }}
        </span>

        <ng-template #customMaximumError>
            <span [innerHtml]="getText(customErrorMessages['max'])"> </span>
        </ng-template>
    </span>

    <span *ngIf="control.errors?.compareDate?.length > 0">
        <ng-container *ngFor="let err of control.errors.compareDate">
            <span *ngIf="!err.errorText; else customCompareDateError">
                {{ 'Surveys.DateMustBe' | translate }} {{ err.condition }}
                {{ 'Surveys.DateFromQuestion' | translate }}
                {{ err.targetQuestion }}
            </span>

            <ng-template #customCompareDateError>
                <span [innerHtml]="getText(err.errorText)"> </span>
            </ng-template>
            <br />
        </ng-container>
    </span>

    <span *ngIf="control.errors?.['pattern']">
        <span
            *ngIf="!customErrorMessages || !customErrorMessages['pattern']; else customPatternError"
        >
            {{ 'Surveys.PatternExpected' | translate }} {{ control.errors.pattern.requiredPattern }}
        </span>
        <ng-template #customPatternError>
            <span [innerHtml]="getText(customErrorMessages['pattern'])"> </span>
        </ng-template>
    </span>

    <!--     
    <div *ngIf="control.errors?.['minSelection']">
        Must select at least {{control.errors?.['minSelection']}} option(s).
    </div> -->
    <div *ngIf="control.errors?.['email']">
        Must be in a format similar to: no-reply&#64;healthie.net
    </div>
    <div *ngIf="control.errors?.['phone']">
        Phone numbers must be at least 10 digits long.
        <br />
        The country code as well as any of the following symbols are optional (+-.)
        <br />
        Example format: +91 (123).456-7890
    </div>
    <span *ngIf="control.errors?.['phoneValidationFailure']">
        {{ 'Surveys.PhoneNotPossible' | translate }}
    </span>
    <span *ngIf="control.errors?.['noChoices']">
        <span>{{ control.errors.noChoices.value }}</span>
    </span>
    <span *ngIf="control.errors?.['notUnique']">
        <span>{{ control.errors.notUnique.value }}</span>
    </span>
</div>

<div
    *ngIf="
        !control?.invalid &&
        warningControl &&
        warningControl.invalid &&
        (control?.dirty || control?.touched)
    "
    class="alert alert-warning mb-0 mt-1 py-2 px-3"
    role="alert"
>
    <span *ngIf="warningControl.errors?.['required']">
        <span
            *ngIf="
                !customWarningMessages || !customWarningMessages['required'];
                else customRequiredWarning
            "
        >
            {{ 'Surveys.RequiredWarning' | translate }}
        </span>

        <ng-template #customRequiredWarning>
            <span [innerHtml]="getText(customWarningMessages['required'])"> </span>
        </ng-template>
    </span>
    <span *ngIf="warningControl.errors?.['min']">
        <span
            *ngIf="
                !customWarningMessages || !customWarningMessages['min'];
                else customMinimumWarning
            "
        >
            {{ 'Surveys.MinimumWarning' | translate: { value: warningControl.errors.min.min } }}
        </span>

        <ng-template #customMinimumWarning>
            <span [innerHtml]="getText(customWarningMessages['min'])"> </span>
        </ng-template>
    </span>

    <span *ngIf="warningControl.errors?.['max']">
        <span
            *ngIf="
                !customWarningMessages || !customWarningMessages['max'];
                else customMaximumWarning
            "
        >
            {{ 'Surveys.MaximumWarning' | translate: { value: warningControl.errors.max.max } }}
        </span>

        <ng-template #customMaximumWarning>
            <span [innerHtml]="getText(customWarningMessages['max'])"> </span>
        </ng-template>
    </span>
</div>
