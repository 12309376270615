<div class="card">
    <div class="card-header">Choices Manager</div>
    <div class="card-body">
        <div *ngFor="let result of results" class="card mb-2">
            <div class="card-header d-flex">
                <p>{{ result.name }}</p>
                <app-button class="ms-auto" (action)="result.toggle()" [buttonText]="'Preview'" />
                <app-button class="ms-2" (action)="setFromDb(result.id)" [buttonText]="'Add'" />
            </div>
            <div *ngIf="result.show" class="card-body">
                <ul>
                    <li *ngFor="let choice of result.item">{{ getText(choice.text) }}</li>
                </ul>
            </div>
        </div>

        <ng-container *ngFor="let ch of choices; index as i">
            <div class="d-flex flex-row justify-content-between align-item-end">
                <label [for]="'cbtext-' + i" class="col-form-label">Text:</label>
                <div
                    [ngClass]="{
                        'col-3 ms-1 me-1':
                            userClaims?.h20ComposerUser || userClaims?.h20ComposerAdmin,
                    }"
                >
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="ch.text"
                        (change)="update()"
                        name="cbtext-{{ i }}"
                        [id]="'cbtext-' + i"
                    />
                </div>
                <label
                    *ngIf="userClaims?.h20ComposerUser || userClaims?.h20ComposerAdmin"
                    [for]="'cbval-' + i"
                    class="col-form-label"
                >
                    Value:
                </label>
                <div
                    *ngIf="userClaims?.h20ComposerUser || userClaims?.h20ComposerAdmin"
                    class="col-3 ms-1 me-1"
                >
                    <input
                        type="text"
                        class="form-control col-2"
                        [(ngModel)]="ch.value"
                        (change)="update()"
                        name="cbval-{{ i }}"
                        [id]="'cbval-' + i"
                    />
                </div>

                <label class="col-form-label col-3" *ngIf="element.showIsExclusive">
                    isExclusive:
                    <input
                        type="checkbox"
                        class="form-check-input"
                        [(ngModel)]="ch.isExclusive"
                        (change)="update()"
                        name="cbexclusive"
                    />
                </label>
                <app-button
                    [design]="'destructive-primary'"
                    (action)="remove(i)"
                    [buttonText]="'Remove'"
                />
            </div>

            <hr />
        </ng-container>

        <button type="button" class="btn btn-success" (click)="add()">Add choice</button>
    </div>
</div>
