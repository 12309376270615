import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { IFormChoice } from '@h20-services/models/forms/IFormChoice';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { RequestService } from '@h20-services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { UuidService } from '@h20-services/uuid.service';

@Component({
    selector: 'app-pi-control-checkbox',
    templateUrl: './pi-control-checkbox.component.html',
    styleUrls: ['./pi-control-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlCheckboxComponent,
        },
    ],
})
export class PiControlCheckboxComponent
    extends AbstractPiControlComponent<IFormElement, any>
    implements OnInit
{
    controlFormGroup: UntypedFormGroup;
    loading = false;

    tooltipOptions = {
        pointerEvents: 'auto',
        placement: 'right',
        'show-delay': 500,
        'hide-delay': 500,
        maxWidth: '500px', // Default max width is 200px
        zIndex: 10021, // Nav bar is 1020, so just need to be over that //modal is at 10000
    };
    getText = this.com_svc.getText;

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        protected fb: UntypedFormBuilder,
        protected req_svc: RequestService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setupFormControls();
    }

    setupFormControls() {
        const group: any = {};
        this.element.choices?.forEach((choice: any) => {
            group[choice.value] = false;
        });

        this.controlFormGroup = this.fb.group(group);
    }

    setDisabledState(disabled: boolean) {
        super.setDisabledState(disabled);
        if (disabled) {
            this.controlFormGroup.disable();
        } else {
            this.controlFormGroup.enable();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    setStringValue() {
        this.stringValue = ''; //reset
        //rebuild

        if (this._value) {
            for (const [k, val] of Object.entries(this._value)) {
                //the keys are the numbers, the value is whether it is ticked or not
                const choiceText = this.getText(
                    this.element.choices.find((el) => el.value == k).text
                );
                if (val) {
                    //if true it is selected
                    //so we add to the string value
                    this.stringValue =
                        this.mode === 'view'
                            ? this.stringValue.concat(choiceText).concat(',')
                            : this.stringValue.concat(choiceText);
                }
            }
            // remove last comma
            if (this.stringValue !== '' && this.mode === 'view')
                this.stringValue = this.stringValue.slice(0, -1);

            this.controlFormGroup = this.fb.group(this._value);
        }
    }

    //override default
    writeValue(theValue: any): void {
        if (theValue) {
            for (const [key, value] of Object.entries(theValue)) {
                //if any value is true this is when it will have a value
                if (value === true) {
                    this._value = theValue;
                    this.setStringValue();
                    return;
                }
            }
            //else it will be null if no options are selected
            //this.formControl.reset();
            this._value = null;
            this.setStringValue();
        }
        //let it be written as null
        this._value = null;
        this.setStringValue();
    }

    onChoiceChange(choice: any): void {
        this.markAsTouched();
        this.checkboxExclusiveRule(choice, this.element, this.controlFormGroup);
        this.writeValue(this.controlFormGroup.value);
        this.notifyValueChange();
    }

    checkboxExclusiveRule(choiceElement, questionElement, formControl): void {
        if (
            this.options?.singleSelect === true ||
            (questionElement.choices &&
                questionElement.choices.find((choice) => choice.isExclusive == true))
        ) {
            if (
                this.options?.singleSelect === true ||
                questionElement.choices.find((choice) => choice.value == choiceElement).isExclusive
            ) {
                //if exclusive checked, uncheck everything else
                questionElement.choices
                    .filter((choice) => choice.value !== choiceElement)
                    .map((choice) => {
                        formControl.value[choice.value] = false;
                    });
            } else {
                //if non-exclusive checked, uncheck all exclusives
                questionElement.choices
                    .filter((choice) => choice.isExclusive == true)
                    .map((choice) => {
                        formControl.value[choice.value] = false;
                    });
            }
            formControl.setValue(formControl.value);
            formControl.updateValueAndValidity();
        }
    }
}
