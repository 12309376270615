import { Component, Input, OnInit } from '@angular/core';
@Component({
    selector: 'app-view-consent',
    templateUrl: './view-consent.component.html',
    styleUrls: ['./view-consent.component.scss'],
})
export class ViewConsentComponent implements OnInit {
    @Input() showConsent;
    @Input() url;
    @Input() saveBtnText;
    @Input() ViewDocSignError;
    @Input() heading;
    @Input() subHeading;
    @Input() docHeight;
    @Input() showCloseBtn;
    @Input() showImpliedConsentMsg;
    @Input() saveFunc: () => void;
    @Input() closeFunc: () => void;

    constructor() {}

    ngOnInit() {}
}
