import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@h20-services/auth.service';
import { FileManagerService } from '@h20-services/file-manager.service';
import { FormService } from '@h20-services/form.service';
import { IFileWithMetaData } from '@h20-services/models/IFileWithMetaData';
import { IFormEntryType } from '@h20-services/models/forms/types/IFormEntryType';
import { DataEntryRoles } from '@h20-services/models/role';
import { ISurvey } from '@h20-services/models/survey/ISurvey';
import { ParticipantService } from '@h20-services/participant.service';
import { UserService } from '@h20-services/user.service';
import { PiFormLayoutFlatComponent } from '@h20-shared/pi-form-layouts/pi-form-layout-flat/pi-form-layout-flat.component';

@Component({
    selector: 'app-pi-form',
    templateUrl: './pi-form.component.html',
    styleUrls: ['./pi-form.component.scss'],
})
export class PiFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    /** Form will create/manage the formGroup for the form being filled out
     * and delegate to specified layout to render the form UI components */
    @Input() mode: string; //passed to layout
    @Input() layout: string; //layout type
    @Input() loading: boolean;
    @Input() progressType: string = 'none'; //supported are 'none' 'dot-nav' and 'progress-bar' (one-by-one only rn)
    @Input() formDef: any; //config
    @Input() formData: any; //data for the form
    @Input() patientId: string; //required for patient-level linked vocabularies
    @Input() surveyId: string;
    @Input() siteId: string; //required for dynamic vocabularies
    @Input() markAllAsTouched: boolean = false;
    @Input() logicalContext: any = {}; //key values for elements not in the form
    @Input() validateOnLoad: boolean; //when it loads should it trigger all validation issues (for a partial on reload for example to show remaining items)
    @Input() entryType: IFormEntryType;

    @Input() parentList: any[]; //specific to survey builder - logic builder

    @ViewChild(PiFormLayoutFlatComponent) piFormFlatComp: PiFormLayoutFlatComponent;

    formGroup: UntypedFormGroup;

    @Output() formChanges: EventEmitter<UntypedFormGroup> = new EventEmitter();

    panels: any;
    logic: any = {};
    listening$: any;
    formDataLoadedFromParent: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        protected form_svc: FormService,
        private fileManageSvc: FileManagerService,
        private auth: AuthService,
        private prtSvc: ParticipantService,
        private userSvc: UserService
    ) {}

    ngAfterViewInit(): void {
        // This was added to allow the Finish Now button to become enabled if the form is valid on load.
        this.formChanges.emit(this.formGroup);
    }

    ngOnInit(): void {
        //TODO this should be consistent between patient and clinical surveys
        if (this.formData?.siteID || this.formData?.site_ID) {
            //if we have form data at initialization
            this.formDataLoadedFromParent = true; //prevent delayed load of initial form data
        }

        //this will generate blank form data
        this.createFormFromSurvey(this.formDef, this.formData);
        this.surveyId = this.formDef['id'];
        if (this.validateOnLoad === true) {
            this.formGroup.markAllAsTouched();
        }
        this.processFormLogic();
        this.panels = this.formDef.pages[0].elements;
        this.listening$ = this.formGroup.valueChanges.subscribe((resp) => {
            this.onFormChange(resp);
        });
    }

    ngOnChanges() {
        if (this.markAllAsTouched === true) {
            this.formGroup.markAllAsTouched();
        }
        if (
            !this.formDataLoadedFromParent &&
            this.formGroup &&
            this.formData &&
            (this.formData.site_ID || this.formData.siteID)
        ) {
            this.formDataLoadedFromParent = true; //only allow one delayed load
            this.formGroup.patchValue(this.formData);
            this.processFormLogic();
        }
    }

    ngOnDestroy() {
        this.listening$.unsubscribe();
    }

    onFormChange(resp): any {
        this.listening$.unsubscribe(); //stop listening while we process

        this.processFormLogic(); //will update the form disabled values

        this.listening$ = this.formGroup.valueChanges.subscribe((resp) => {
            this.onFormChange(resp);
        });

        //emit the event
        this.formChanges.emit(this.formGroup);
    }

    /**
     * Creates a FormGroup from the definition and existing data if provided
     * The form group must be created with the set of keys and Abstract controls
     * it will use.  This initializes all those controls and puts the data from
     * the surveyData parameter, if supplied, as the initial value of the new control.
     * @param surveyDef6n The json definition of the survey loaded as an object
     * @param surveyData The data for the survey loaded to an object
     */
    createFormFromSurvey(surveyDef6n: ISurvey, surveyData?: any) {
        const controls = {};
        surveyDef6n.pages.forEach((page) =>
            page.elements.forEach((panel) =>
                panel.elements.forEach((element) => {
                    controls[element.name] = this.form_svc.createControl(element, surveyData);
                })
            )
        );

        // Create the form
        this.formGroup = this.fb.group(controls);
    }

    processFormLogic() {
        //combine current form values with logical context values - allow current form to overwrite context
        let values = { ...this.logicalContext, ...this.formGroup.value };

        for (const page of this.formDef.pages) {
            for (const panel of page.elements) {
                for (const elt of panel.elements) {
                    const ctrl = this.formGroup.controls[elt.name];

                    if (this.form_svc.processVisibilityLogic(elt, values)) ctrl.enable();
                    else ctrl.disable();
                }
            }
        }
    }

    async downloadEventHandlerFunction(valueEmitted: any) {
        const emittedJson = JSON.parse(valueEmitted);
        const pulseAuth = await this.auth.getPulseAuth();
        const submissionType = DataEntryRoles.includes(pulseAuth.getType())
            ? 'clinician-submissions'
            : 'patient-submissions';
        const folderName = `${
            this.formData.site_ID || this.formData.siteID
        }/private/${submissionType}/${this.formData.patient_ID || this.patientId}/file-uploads/`;
        this.fileManageSvc
            .getFileWithMetaDataByName(emittedJson.fileName, folderName)
            .subscribe((file: IFileWithMetaData) => {
                let fileName = file.fileName || file.name;
                let fileId = file.fileId || file.id;
                const ext = fileName.substring(fileName.lastIndexOf('.'));
                const filePath = `${folderName}${fileId}${ext}`;
                this.fileManageSvc.downloadFile(filePath, fileName);
            });
    }

    @Output() stageFileUploadChanges: EventEmitter<any> = new EventEmitter();

    stageFileUploadChangesHandlerFunction(files) {
        this.stageFileUploadChanges.emit(files);
    }

    @Output() stageFileDeleteChanges: EventEmitter<any> = new EventEmitter();

    stageFileDeleteChangesHandlerFunction(files) {
        this.stageFileDeleteChanges.emit(files);
    }
}
