<div class="tab-content" id="nav-tabContent">
    <div style="background-color: #e6e6e6">
        <div class="border my-3">
            <app-pi-form-rtv
                *ngIf="!hotReload"
                [formDef]="survey"
                (formChanges)="onOtherFormChanges($event)"
                [layout]="currentLayout"
                [progressType]="currentLayout === 'one-by-one' ? 'progress-bar' : 'none'"
            ></app-pi-form-rtv>
        </div>
    </div>
</div>
