import { Component, Input, OnInit } from '@angular/core';
import { DeployService } from '@h20-services/deploy.service';
import { IConfigTable } from '@h20-services/models/deploy/IConfigTable';
import { EnvironmentService } from '@h20-services/environment.service';
import { ObjectDiffService } from '@h20-shared/object-diff/object-diff.service';
import { LoadingComponent } from '@h20-shared/loading-component/loading-component.component';

@Component({
    selector: 'app-compare-deploy-config',
    templateUrl: './compare-deploy-config.component.html',
    styleUrls: ['./compare-deploy-config.component.scss'],
})
export class CompareDeployConfigComponent implements OnInit {
    constructor(
        private environmentSvc: EnvironmentService,
        private deploySvc: DeployService,
        private objectDiff: ObjectDiffService
    ) {
        this.environmentSvc.currentSource.subscribe((environment) => {
            this.sourceEnv = environment;
        });
        this.environmentSvc.currentDestination.subscribe((environment) => {
            this.destinationEnv = environment;
        });
    }

    //Used
    sourceEnv: string;
    destinationEnv: string;

    // Variables for compare items
    sourceItemRest: any;
    destItemRest: any;
    sourceView;
    destView;
    diffView;
    diffValueChanges;

    sourceConfigItems: any[];
    @Input() selectedItemKey: string;
    @Input() selectedTable: IConfigTable;
    sourceItem: any = {};
    destItem: any = {};

    message: string = '';

    configTables: IConfigTable[];
    configItems: any[];
    configTableAttributes = [];

    selectedTableKey: string = '';
    compareLoading: boolean = false;

    ngOnInit(): void {
        this.selectedTableKey = this.selectedTable.tableKey;
        this.loadCompareItems();
    }

    loadCompareItems() {
        this.compareLoading = true;

        Promise.all([
            this.deploySvc
                .getConfigItem(this.selectedTableKey, this.selectedItemKey, this.sourceEnv)
                .toPromise(),
            this.deploySvc
                .getConfigItem(this.selectedTableKey, this.selectedItemKey, this.destinationEnv)
                .toPromise(),
        ])
            .then(([sourceConfRes, destConfRes]) => {
                this.sourceItem = sourceConfRes;
                this.sourceItemRest = this.getRestProperty(this.sourceItem);

                if (destConfRes) {
                    this.destItem = destConfRes;
                    this.destItemRest = this.getRestProperty(this.destItem);

                    this.sourceView = this.objectDiff.objToJsonView(this.sourceItemRest);
                    this.destView = this.objectDiff.objToJsonView(this.destItemRest);
                    let diff = this.objectDiff.diff(this.destItemRest, this.sourceItemRest);
                    this.diffView = this.objectDiff.toJsonView(diff);
                    this.diffValueChanges = this.objectDiff.toJsonDiffView(diff);
                } else {
                    this.sourceView = this.objectDiff.objToJsonView(this.sourceItemRest);
                    this.message = `The config item doesn't exist on ${this.destinationEnv}`;
                }

                this.compareLoading = false;
            })
            .catch((err) => {
                this.message = `Error on getting config item on ${this.destinationEnv}`;
                this.compareLoading = false;
            });
    }

    getRestProperty(item): any {
        const {
            [this.selectedTable.partitionKeyName]: removePK,
            ['version']: removeSK,
            ['latest_version']: removeLatestVersion,
            ['created']: removeCreated,
            ...rest
        } = item;
        return rest;
    }
}
