import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ElementDesignerConfigService } from '../../survey-designer/element-designer/element-designer-config.service';
import { firstValueFrom } from 'rxjs';
import { SurveyService } from '@h20-services/survey.service';
import { ELEMENT_TYPES } from '@h20-services/enums/elementTypes/ElementType';

@Component({
    selector: 'app-rtv-configuration-section',
    templateUrl: './rtv-configuration-section.component.html',
    styleUrls: ['./rtv-configuration-section.component.scss'],
})
export class RtvConfigurationSectionComponent implements OnChanges {
    constructor(
        private surveyService: SurveyService,
        private designerConfigService: ElementDesignerConfigService,
        private changeDetector: ChangeDetectorRef
    ) {}
    element: any; //this elements
    elements: any;
    @Input() survey;
    @Input() elementIndex;
    @Input() currentElement;
    @Input() panelIndex;
    formForm: any;
    designerForm;
    elementType;
    elementDisplayText;
    generalForm = { pages: [{ elements: [] }] };
    layoutForm = { pages: [{ elements: [] }] };
    logicForm = { pages: [{ elements: [] }] };
    tabName = 'general';
    options = {
        condensed: true,
    };
    hotReload = false;
    @Output() elementChange = new EventEmitter<any>();

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['survey'] ||
            changes['elementIndex'] ||
            changes['panelIndex'] ||
            changes['currentElement']
        ) {
            this.updateConfig();
        }
    }

    updateConfig(): void {
        this.elements = this.survey.pages[0].elements[this.panelIndex].elements;
        this.elementType = this.elements[this.elementIndex].type;
        this.elementDisplayText = ELEMENT_TYPES.find((el) => {
            return el.text === this.elementType;
        })?.displayText;
        firstValueFrom(this.designerConfigService.getFullConfig(this.elementType))
            .then((valForm) => {
                this.designerForm = this.surveyService.concatenateSurveys('Element Form', valForm);
                this.expandedTab(this.tabName);
                this.doHotReload();
            })
            .catch((reason) => {
                console.error('Failed to load SB configurator survey config for some reason?');
                console.error(reason);
            });
    }
    onFormChanges(event: any) {
        if (this.currentElement.name !== event.value.name) {
            const panelInd = event.value;
            const elIndex = this.elementIndex;
            Object.assign(this.currentElement, this.nonNullValues(event.value));
            this.elementChange.emit({ elIndex, panelInd });
        } else {
            Object.assign(this.currentElement, this.nonNullValues(event.value));
        }
    }

    nonNullValues(obj: any): any {
        return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null));
    }

    doHotReload(): void {
        this.hotReload = true;
        this.changeDetector.detectChanges();
        this.hotReload = false;
    }

    deleteAt() {
        this.survey.pages[0].elements[this.panelIndex].elements.splice(this.elementIndex, 1);
        this.elements = this.survey.pages[0].elements[this.panelIndex].elements;
        if (this.elements.length === 0) {
            this.currentElement = [];
        } else {
            if (this.elementIndex === this.elements.length) {
                this.currentElement = [];
            } else this.currentElement = this.elements[this.elementIndex];
        }
        this.doHotReload();
    }

    expandedTab(tabName) {
        this.tabName = tabName;
        this.generalForm = null;
        this.layoutForm = null;
        this.logicForm = null;
        switch (this.tabName) {
            case 'general':
                this.generalForm = { pages: [{ elements: [] }] };
                this.generalForm.pages[0].elements = this.designerForm.pages[0].elements.filter(
                    (el) =>
                        el.name === 'element' ||
                        el.name === 'inputType' ||
                        el.name === 'fileuploadType' ||
                        (this.elementType === 'text'
                            ? el.name === 'inputType'
                            : el.name === this.elementType) ||
                        el.name === 'element_others'
                );
                return;
            case 'layout':
                this.layoutForm = { pages: [{ elements: [] }] };
                this.layoutForm.pages[0].elements = this.designerForm.pages[0].elements.filter(
                    (el) => el.name === 'layout'
                );
                return;
            case 'logic':
                this.logicForm = { pages: [{ elements: [] }] };
                this.logicForm.pages[0].elements = this.designerForm.pages[0].elements.filter(
                    (el) =>
                        el.name === 'logic' || el.name === 'validators' || el.name === 'warnings'
                );
                return;
            default:
                return '';
        }
    }
}
