<div class="generic-badge-container" [ngClass]="[size]">
    <div [ngClass]="['generic-tag', size, color]" *ngIf="!dotBadge">
        <ng-container *ngIf="icon === 'dot'">
            <span class="generic-badge-dot"></span>
        </ng-container>
        <span class="generic-badge-text">{{ label | translate }}</span>
    </div>

    <span *ngIf="dotBadge && !isInteractive" attr.aria-label="{{ ariaLabel | translate }}" tabindex=0 class="dot-badge"
        [ngClass]="color" [class.interactive]="isInteractive" atrr.aria-label="ariaLabel">
    </span>

    <button *ngIf="isInteractive" (click)="click($event)">
        <div attr.aria-label="{{ ariaLabel | translate }}" *ngIf="dotBadge" class="dot-badge" [ngClass]="color"
            [class.interactive]="isInteractive" attr.aria-label="{{ ariaLabel | translate }}">
        </div>
    </button>
</div>