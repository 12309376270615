<div class="col-12 position-relative mt-1">
    <div *ngIf="message" class="text-center text-danger mb-2">
        {{ message }}
    </div>
    <app-loading-component [loading]="compareLoading">
        <div class="row">
            <div class="col-6">
                <label>Source: {{ selectedTableKey }} in {{ sourceEnv }} </label>
                <label *ngIf="selectedTable"
                    >Key: {{ sourceItem[this.selectedTable.partitionKeyName] }}
                </label>
                <label
                    >Latest Version: {{ sourceItem.latest_version || sourceItem.version }}
                </label>
                <label>Created: {{ sourceItem.created }} </label>
                <app-object-diff [obj]="sourceView"></app-object-diff>
            </div>
            <div class="col-6">
                <label>Destination: {{ selectedTableKey }} in {{ destinationEnv }}</label>
                <label *ngIf="selectedTable"
                    >Key: {{ destItem[this.selectedTable.partitionKeyName] }}
                </label>
                <label>Latest Version: {{ destItem.latest_version || destItem.version }} </label>
                <label>Created: {{ destItem.created }}</label>
                <app-object-diff [obj]="destView"></app-object-diff>
            </div>
        </div>
        <div class="mt-3">
            <label>Different Values Only </label>
            <small class="form-text text-muted">
                Green: Source config & Red: Destination config
            </small>
            <app-object-diff [obj]="diffValueChanges"></app-object-diff>
            <label>All Values</label>
            <app-object-diff [obj]="diffView"></app-object-diff>
        </div>
    </app-loading-component>
</div>
