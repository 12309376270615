import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { AuthService } from '@h20-services/auth.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { TaskStatus } from '@h20-services/models/status';

@Component({
    selector: 'app-task-card',
    providers: [DatePipe],
    templateUrl: './task-card.component.html',
    styleUrls: ['./task-card.component.scss'],
})
export class TaskCardComponent implements OnInit {
    @Input() heading = '';
    @Input() subHeading = '';
    @Input() dueTimeStyle = {};
    @Input() disabled = false;
    @Input() active = false;
    @Input() status: string;
    @Input() dueTime: string;
    @Input() modified: Date;
    @Output() viewCard = new EventEmitter<void>();

    color: string;
    barColor: string;
    label: string;
    dueTimeString;
    iconName: string = '';
    type: string;
    buttonText: string;
    buttonDesign: string;
    disable = false;
    showCardButton = true;
    selectedDateFormat;
    dueTimeColor;
    dueOnDate;
    disableButton;

    constructor(
        public translate: TranslateService,
        private datePipe: DatePipe,
        private auth: AuthService
    ) {}

    ngOnInit(): void {
        this.setCardAttributes();
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            this.selectedDateFormat = pulseAuth.getPreferredDateFormat();
            this.calUpcomingDate(this.dueTime);

            if (this.status === TaskStatus.Completed) {
                this.formatDate(this.modified);
            }

            const claims = pulseAuth.getClaims();
            this.disableButton = !claims?.mySurveys || false;
            this.setCardAttributes();
        });
        this.setDueTime();
    }

    private setCardAttributes() {
        const taskStatusConfig = {
            [TaskStatus.Opened]: {
                barColor: 'var(--blue-600)',
                color: 'blue',
                label: 'PatientTask.InProgress',
                iconName: 'fa-regular fa-hourglass-start',
                type: 'primary',
                buttonText: 'Registry.Continue',
                buttonDesign: 'secondary',
                disable: false,
                showCardButton: true,
            },
            [TaskStatus.New]: {
                barColor: 'var(--blue-600)',
                color: 'blue',
                label: 'PatientTask.New',
                iconName: 'fa-regular fa-file-lines',
                type: 'primary',
                buttonText: 'Registry.Start',
                buttonDesign: 'primary',
                disable: false,
                showCardButton: true,
            },
            [TaskStatus.Upcoming]: {
                barColor: 'var(--gray-light-700)',
                color: 'gray',
                label: 'PatientTask.Upcoming',
                iconName: 'fa-regular fa-clock',
                type: 'primary',
                buttonText: 'Registry.Start',
                buttonDesign: 'secondary',
                disable: false,
                showCardButton: true,
            },
            [TaskStatus.Completed]: {
                barColor: 'green',
                color: 'green',
                label: 'PatientTask.Complete',
                iconName: 'fa-regular fa-check',
                type: 'success',
                buttonText: 'Registry.View',
                buttonDesign: 'secondary',
                disable: this.disableButton,
                showCardButton: !this.disableButton,
            },
            [TaskStatus.Expired]: {
                barColor: 'var(--gray-light-700)',
                color: 'gray',
                label: 'PatientTask.Expired',
                iconName: 'fa-regular fa-triangle-exclamation',
                type: 'default',
                buttonText: 'Registry.Start',
                buttonDesign: 'secondary',
                disable: true,
                showCardButton: true,
            },
        };

        const config = taskStatusConfig[this.status];

        if (config) {
            this.barColor = config.barColor;
            this.color = config.color;
            this.label = config.label;
            this.iconName = config.iconName;
            this.type = config.type;
            this.buttonText = config.buttonText;
            this.buttonDesign = config.buttonDesign;
            this.disable = config.disable;
            this.showCardButton = config.showCardButton;
        }
    }

    setDueTime(): void {
        if (
            !this.dueTime ||
            this.status === TaskStatus.Expired ||
            this.status === TaskStatus.Upcoming
        ) {
            this.dueTimeString = undefined;
            return;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const dueDate = new Date(this.dueTime);
        dueDate.setHours(0, 0, 0, 0);

        if (isNaN(dueDate.getTime())) {
            this.dueTimeString = undefined;
            return;
        }
        const daysDiff = Math.floor((dueDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
        this.setDueTimeColor(daysDiff);
        this.setDueTimeString(daysDiff, dueDate);
    }

    calUpcomingDate(dueDate) {
        if (this.status === TaskStatus.Upcoming) {
            this.dueTimeString = `${this.translate.instant(
                'PatientTask.ComingUp'
            )}: ${this.formatDate(dueDate)}`;
        }
    }

    formatDue(count: number, unit: string) {
        return `${this.translate.instant('PatientTask.DueIn')} ${count} ${this.translate.instant(
            unit
        )}${count > 1 ? 's' : ''}`;
    }

    setDueTimeColor(daysDiff) {
        let dueTimeColor = 'var(--error-600)';
        if (this.status === TaskStatus.Upcoming) {
            dueTimeColor = 'var(--gray-light-500)';
        } else if (daysDiff > 7 && daysDiff <= 14) {
            dueTimeColor = 'var(--warning-600)';
        } else if (daysDiff > 14) {
            dueTimeColor = 'var(--gray-light-500)';
        }
        this.dueTimeStyle = { color: dueTimeColor };
        this.dueTimeColor = dueTimeColor;
    }

    setDueTimeString(daysDiff, dueDate) {
        if (daysDiff === 0) {
            this.dueTimeString = this.translate.instant('PatientTask.DueToday');
        } else if (daysDiff === 1) {
            this.dueTimeString = this.translate.instant('PatientTask.DueTomorrow');
        } else if (daysDiff > 1 && daysDiff < 7) {
            this.dueTimeString = this.formatDue(daysDiff, 'PatientTask.Day');
        } else if (daysDiff === 7) {
            this.dueTimeString = this.formatDue(1, 'PatientTask.Week');
        } else if (daysDiff > 7 && daysDiff < 14) {
            this.dueTimeString = this.formatDue(daysDiff, 'PatientTask.Day');
        } else if (daysDiff === 14) {
            this.dueTimeString = this.formatDue(2, 'PatientTask.Week');
        } else if (daysDiff > 14 && daysDiff < 30 && daysDiff % 7 === 0) {
            this.dueTimeString = this.formatDue(daysDiff / 7, 'PatientTask.Week');
        } else if (daysDiff === 30 || daysDiff === 31) {
            this.dueTimeString = this.translate.instant('PatientTask.Month');
        } else if (daysDiff > 14 && daysDiff < 30) {
            this.dueTimeString = this.formatDue(daysDiff, 'PatientTask.Day');
        } else if (daysDiff > 31) {
            this.dueTimeString = this.formatDate(dueDate);
        } else {
            this.dueTimeString = undefined;
        }
    }

    formatDate(date: Date) {
        this.selectedDateFormat = this.selectedDateFormat ? this.selectedDateFormat : 'MM/dd/yyyy';
        this.selectedDateFormat = this.selectedDateFormat.replace(/mm/g, 'MM');
        const dueDate = this.datePipe.transform(date, this.selectedDateFormat) || '';
        if (this.status === TaskStatus.Completed) {
            this.dueTimeString = `${this.translate.instant('PatientTask.Completed')} ${dueDate}`;
        } else {
            return `${this.translate.instant('PatientTask.DueOn')} ${dueDate}`;
        }
    }

    onClick() {
        if (!this.disabled && this.showCardButton) {
            this.viewCard.emit();
        }
    }

    handleKeydown(event: KeyboardEvent): void {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            this.onClick();
        }
    }
}
