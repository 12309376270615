import { Component, Injector, Input, Output, OnInit, EventEmitter, HostListener } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { SurveyService } from '@h20-services/survey.service';
import { IFormChoice } from '@h20-services/models/forms/IFormChoice';
import { RequestService } from '@h20-services/request.service';
import { UuidService } from '@h20-services/uuid.service';
import { AuthService } from '@h20-services/auth.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { IFormMultiselect } from '@h20-services/models/forms/types/IFormMultiselect';

@Component({
    selector: 'app-pi-control-multiselect',
    templateUrl: './pi-control-multiselect.component.html',
    styleUrls: ['./pi-control-multiselect.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlMultiselectComponent,
        },
    ],
})
export class PiControlMultiselectComponent
    extends AbstractPiControlComponent<IFormMultiselect, any>
    implements OnInit
{
    @Input() items: any[] = []; // List of items to display
    @Input() isMultiSelect: boolean = true; // Multi-select enabled
    @Input() enableSearch: boolean = true; // Search enabled or not
    @Input() scrollable: boolean = true; // Enable scroll
    @Input() patientId: any;
    @Input() surveyId: any;

    @Output() selectionChange = new EventEmitter<any[]>();

    selectedItems: any[] = [];
    searchQuery: string = '';
    dropdownOpen: boolean = false; // Controls whether the dropdown is open
    
    loading = false;
    urlChoices: any[];
    //we need to use a stand for the form value so that we can deselect
    currentChoice: any;

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        private req_svc: RequestService,
        protected translate: TranslateService,
        private survey_svc: SurveyService,
        private authService: AuthService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    async ngOnInit() {
        super.ngOnInit();
        if (this.element.hasOwnProperty('noChoicesError')) {
            this.formControl.addValidators(this.noChoicesValidator);
            this.formControl.markAsTouched();
        }

        this.currentChoice = this._value;
    }

    writeValue(value: any): void {
        //override to sync the dropdown UI on load
        //don't set current choice unless it has a value - for null currentChoice should be '' so it shows 'Select Response'
        //we have this here so when the initial value loads, it will show that in the UI component
        if (value !== null) this.currentChoice = value;
        super.writeValue(value);
    }

    selectionMade(event: any) {
        //need to write the value to show in the current selection including ''
        this.currentChoice = event.target.value;
        this.markAsTouched();
        // if the value is 'Select Response' - set the form value to null
        if (event.target.value === '') {
            this.writeValue(null);
        } else this.writeValue(event.target.value);
        this.notifyValueChange();
    }

    async setStringValue() {
        if (this._value === null || !this.element.choices) return;

        const choice = this.element.choices.find((c) => c.value == this._value);
        this.stringValue = this.getText(choice?.text);
    }

    noChoicesValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            //always returns
            return { noChoices: { value: this.translate.instant('Surveys.PleaseCloseForm') } };
        };
    }

    // -- Above thisis from the original pi-form dropdown
    // -- This is from the multi-select shared component

    // Toggle the dropdown open/close
    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    // Handle item selection and update selected items
    toggleSelection(item: any) {
        // If the item is already selected, remove it from the list
        if (this.selectedItems.includes(item)) {
            this.selectedItems = this.selectedItems.filter((i) => i !== item);
        } else {
            // Add the item to the selected list
            if (this.isMultiSelect) {
                this.selectedItems.push(item); // Add to the array for multi-selection
            } else {
                this.selectedItems = [item]; // Replace the selection for single-selection
                this.dropdownOpen = false; // Close dropdown if single-select
            }
        }
        // Emit the updated selected items
        this.selectionChange.emit(this.selectedItems);

        // This is for the form values
        this.setSelectedValues();
    }

    // Remove selected item (for multi-select)
    removeItem(item: any) {
        this.selectedItems = this.selectedItems.filter((i) => i !== item);
        this.selectionChange.emit(this.selectedItems);
        this.setSelectedValues();
    }

    // Filter the dropdown items based on search query
    get filteredItems() {
        return this.searchQuery
            ? this.element.choices.filter((item) =>
                this.getText(item.text).toLowerCase().includes(this.searchQuery.toLowerCase())
              )
            : this.element.choices;
    }

    // Detect clicks outside the dropdown to close it
    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
        const targetElement = event.target as HTMLElement;
        if (!targetElement.closest('.dropdown-container')) {
            this.dropdownOpen = false;
        }
    }

    setSelectedValues() {
        this.currentChoice = this.selectedItems.map(item => item.value);
        this.markAsTouched();
        // if the value is 'Select Response' - set the form value to null
        if (this.currentChoice === '' || this.currentChoice.length == 0) {
            this.writeValue(null);
        } else this.writeValue(this.currentChoice);
        this.notifyValueChange();
    }
}
